<template>
  <div class="LeftInfoContainer">
    <!-- <ContentTop title="健身房信息" :type="2" />
    <div class="rank-list" style="margin-top: 20px">
      <rank-list :listHeight="250" :tableTitle="tableTitle" :list="areaData" />
    </div> -->
    <img src="../images/rightInfo2.png" alt="">
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
import service from "@/utils/request";
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      tableTitle: [
        {
          title: '排名',
          width: '100',
        },
        {
          title: '器械名称',
          val: 'first',
          width: '150',
        },
        {
          title: '使用人数',
          val: 'second',
          width: '150',
        },
      ],
      areaData: [],
      timing: null,
    }
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      // 健身房信息
      const myThis = this;
      service.get('/gym/userData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;

            myThis.areaData = data.map((item) => {
              return {
                first: item.name,
                second: item.use_num
              }
            });
          })
          .catch((response) => {
            console.log(response)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.LeftInfoContainer {
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
