<template>
  <div class="RightCenterContainer">
    <div class="leftBox">
      <div class="imgBox">
        <img src="~@/assets/sportFacilities/alarm.png" alt="" />
      </div>
      <div class="des">场馆数字化不通预警</div>
    </div>
    <div class="rightBox">
      <div class="countItem tabWarm">
        <div class="countBox">
          <span class="count">13</span><span class="label">起</span>
        </div>
        <div>场馆数字化不同</div>
      </div>
      <div class="countItem tabTip">
        <div class="countBox">
          <span class="count">0</span><span class="label">起</span>
        </div>
        <div>无订单</div>
      </div>
      <div class="countItem tabTip">
        <div class="countBox">
          <span class="count">0</span><span class="label">起</span>
        </div>
        <div>无流量</div>
      </div>
    </div>
  </div>
</template>
              
              <script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
              
              <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.RightCenterContainer {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   background: red;
  .leftBox {
    .imgBox {
      width: 209px;
      height: 116px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .des {
      text-align: center;
      margin-top: 18px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
    }
  }
  .rightBox {
    .countItem {
      width: 182px;
      height: 56px;
      background-size: cover;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 70px;
      padding-bottom: 5px;
      &:first-child {
        margin-top: 0;
      }
      &.tabWarm {
        background-image: url("~@/assets/sportFacilities/warning_tab.png");
      }
      &.tabTip {
        background-image: url("~@/assets/sportFacilities/tip_tab.png");
        .countBox {
          .count {
            background-image: linear-gradient(
              180deg,
              #ffffff,
              #10ffff 100%
            ) !important;
          }
        }
      }

      .countBox {
        position: relative;
        top: -3px;
        .count {
          background-image: -webkit-linear-gradient(
            180deg,
            #ffffff,
            #e14850 100%
          );
          font-size: 26px;
          font-family: DIN, DIN-Bold;
          font-weight: 700;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .label {
          margin-left: 6px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
              