<template>
	<div class="container">
		<div class="leftView">
			<div class="top">
				<ContentTop title="运动币兑换类型" :type="2" :topOptionsProps="sportExchangeTitle"
					@topSelectChange="topSelectChange" />
				<piechart class="sportExchangeView" :pieData="sportExchangeOptions" :basicConfig="basicConfig"
					:height="300"></piechart>
				<div class="legendList">
					<div class="legendItem" v-for="(item,index) in sportExchangeOptions" :key="index">
						<span :style="`background-color:${item.itemStyle.color}`"></span>
						{{item.name}}
					</div>
				</div>
			</div>
			<div class="foot">
				<ContentTop title="运动币获得方式" :type="2" style="margin-bottom:30px;" />
        <line-chart :cdata="barIncomeOptions"  contWidth="100%" contHeight="320px"/>
			</div>
		</div>
		<div class="centerView">
			<div class="top">
				<p>
					<img src="@/assets/sportsBank/right.png" alt="">
					<span>运动币发放总数</span>
					<img src="@/assets/sportsBank/right.png" alt="">
				</p>
				<light-plate style="width:90%;margin:20px auto;" :lightNum="basicInfo.totalNum" />
				<p>
					<span>运动币已兑换数量:{{basicInfo.usedNum}}个</span>
					<span>运动币市面剩余数量:{{basicInfo.availableNum}}个</span>
				</p>
			</div>
			<div class="lineView">
				<p class="title">运动币产出与消费</p>
				<line-chart :cdata="barOptions"  contWidth="100%" contHeight="700px"/>
			</div>
		</div>
		<div class="rightView">
			<div class="top">
				<ContentTop title="运动币获得排行榜" :type="2" style="margin-bottom:10px;" />
				<rank-list :listWidth="400" :listHeight="840"  :tableTitle="tableTitle" :list="tableList"/>
			</div>
		</div>
	</div>
</template>

<script>
	import drawMixin from "@/utils/drawMixin";
	import rankList from "@/components/rankList";
	import lightPlate from "@/components/lightPlate";
	import Echart from '@/common/echart'
	import lineChart from '@/components/lineChart'
	import echarts from 'echarts'
	import ContentTop from '@/components/contentTop';
	import piechart from '@/components/pieChart';
  import service from "@/utils/request";

	export default {
		mixins: [drawMixin],
		components: {
			rankList,
			lightPlate,
			Echart,
			lineChart,
			ContentTop,
			piechart
		},
		data() {
			return {
        basicInfo: {
          totalNum: [],
          usedNum: 0,
          availableNum: 0
        },
        tableTitle: [
          {
            title: '排名',
            width: '80',
          },
          {
            title: '用户姓名',
            val: 'first',
            width: '200',
          },
          {
            title: '数量',
            val: 'second',
            width: '120',
          },
        ],
        tableList: [],
				//运动币兑换类型
				sportExchangeOptions: [],
				//运动币饼图图例设置
				basicConfig: {
					top: 'auto',
					right: 'auto',
					left: 'center',
					bottom: '1%',
					showLegend: false,
					transparent: 0.9,
					pieOpacity: 0.8,
					needInterval: false,
				},
				//运动币兑换标题筛选
				sportExchangeTitle: { // 体育社会组织信息
					selects: []
				},
				//中间柱状图数据
				barOptions: {},
				//中间折线图数据
				lineOptions: {},
        barIncomeOptions: {},
        timing: null,
			};
		},
		created() {
			//this.contHeight = document.body.clientHeight - 100;
			//console.log(this.contHeight);

		},

		mounted() {
      this.getbasicInfoData();
      this.gettypeData();
      this.getincomeData();
      this.getincomeRankingData();
      this.timeFn();
		},
		beforeDestroy() {
			clearInterval(this.timing);
		},
		methods: {
      timeFn() {
        this.timing = setInterval(() => {
          this.getbasicInfoData();
          this.gettypeData();
          this.getincomeData();
          this.getincomeRankingData();
        }, 5000);
      },
      getbasicInfoData() {
        // 基础数据（屏幕中间）
        const myThis = this;
        service.get('/bank/basicInfo')
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              let total = data.total + '';
              if(total.length < 8) {
                const length = 8 - total.length;
                for (let i = 0; i < length; i++) {
                  total = '0'+total;
                }
              }
              myThis.basicInfo.totalNum = total.split("");
              myThis.basicInfo.usedNum = data.used_num;
              myThis.basicInfo.availableNum = data.available_num;
              myThis.setCenterBar(data.list);
            })
            .catch((response) => {
              console.log(response)
            })
      },
      gettypeData() {
        // 运动币兑换类型
        const myThis = this;
        service.get('/bank/typeData')
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              const colorList = ['#2B8EF3','#564AF1','#27B9CC','#5AC3FF','#3CD495','#BEE5FB','#3254DD', '#48E5E5'];
              myThis.sportExchangeOptions= data.map((item,index) => {
                return {
                  name: item.name,
                  value: item.user,
                  itemStyle: {
                    color: colorList[index] || colorList[0],
                  }
                }
              });
            })
            .catch((response) => {
              console.log(response)
            })
      },
      getincomeData() {
        // 运动币获得方式
        const myThis = this;
        service.get('/bank/incomeData')
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              myThis.barIncomeOptions = {
                tooltip: {
                  trigger: 'axis',
                  borderColor:'#2DCDFF',
                  borderWidth:1,
                  backgroundColor:'rgba(0,0,0,0.5)',
                },
                legend: {
                  show: false,
                },
                xAxis: [{
                  type: 'category',
                  data: data.map((item) => item.name),
                  axisPointer: {
                    type: 'shadow'
                  }
                }],
                yAxis: [{
                  type: 'value',
                  name: '个',
                  axisLabel: {
                    formatter: function(value, index) {
                      return value;
                    }
                  }
                }],
                series: [{
                  name: '',
                  type: 'bar',
                  legendHoverLink: false,
                  barWidth: '20px',
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0,
                        color: 'rgba(8,253,254,0)'
                      },
                        {
                          offset: 1,
                          color: 'rgb(254,102,8)'
                        }
                      ], false)
                    }

                  },
                  data: data.map((item) => item.num)
                }]
              };
            })
            .catch((response) => {
              console.log(response)
            })
      },
      getincomeRankingData() {
        // 运动币获得排行榜
        const myThis = this;
        service.get('/bank/incomeRanking')
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              myThis.tableList= data.map((item,index) => {
                return {
                  first: item.name,
                  second: item.num
                }
              });
            })
            .catch((response) => {
              console.log(response)
            })
      },
			//柱状图
			setCenterBar(data) {
        const xdata = data.map((item) => item.month);
				let barOptions = {
					tooltip: {
						trigger: 'axis',
						borderColor:'#2DCDFF',
						borderWidth:1,
						backgroundColor:'rgba(0,0,0,0.5)',
						formatter: (params) => {
							let res = "";
							res = `
							            <div style="padding:8px 12px;color:#fff">
							              <div>运动币产出与消费：</div>
							              <div style="font-size: 12px;padding-top: 6px">当月收入：${params[0]?.data}</div>
							              <div style="font-size: 12px;padding-top: 6px">当月支出：${params[1]?.data}</div>
							            </div>`;
							return res;
						}
					},
					legend: {
						show: false,
					},
					xAxis: [{
						type: 'category',
						data: xdata,
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: [{
						type: 'value',
						name: '个',
						axisLabel: {
							formatter: function(value, index) {
								return value;
							}
						}
					}],
					series: [{
						name: '运动币当月收入',
						type: 'bar',
						legendHoverLink: false,
						barWidth: '20px',
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
										offset: 0,
										color: 'rgba(8,253,254,0)'
									},
									{
										offset: 1,
										color: 'rgba(8,253,254,1)'
									}
								], false)
							}

						},
						data: data.map((item) => item.available_num)
					},
            {
              name: '运动币当月支出',
              type: 'bar',
              legendHoverLink: false,
              barWidth: '20px',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: 'rgba(8,253,254,0)'
                  },
                    {
                      offset: 1,
                      color: 'rgb(8,78,254)'
                    }
                  ], false)
                }

              },
              data: data.map((item) => item.used_num)
            }
          ]
				};

				this.barOptions = {
					...barOptions
				};
			},
			//折线图
			setCenterLine() {
				let lineOptions = {
					tooltip: {
						trigger: 'axis',
						borderColor:'#FCBC02',
						borderWidth:1,
						backgroundColor:'rgba(0,0,0,0.5)',
						formatter: (params) => {
							let res = "";
							res = `
							            <div style="padding:8px 12px;color:#fff">
							              <div>运动币兑换情况：</div>
							              <div style="font-size: 12px;padding-top: 6px">总数：${params[0]?.data.value}</div>
							              <div style="font-size: 12px;">教去年：${params[0]?.data.upVal}%  <span class="iconfont icon-shang" style="font-size: 16px; color: #FCBC02;" /></div>
							            </div>`;
							return res;
						}
					},
					legend: {
						show: false,
					},
					xAxis: [{
						type: 'category',
						data: ['瓯海区', '龙湾区', '鹿城区', '永嘉县', '乐青市', '瑞安市', '文成县', '平阳县', '龙港市', '洞头区',
							'泰顺县',
							'苍南县'
						],
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: [{
						type: 'value',
						name: '万个',
						min: 0,
						max: 80000,
						interval: 20000,
						axisLabel: {
							formatter: function(value, index) {
								return value / 10000;
							}
						}
					}],
					series: [{
						name: '运动币产出情况',
						type: 'line',
						hoverAnimation: false,
						itemStyle: {
							color: '#FCBC02',
						},
						lineStyle: {
							width: 4,
						},
						barWidth: '20px',
						symbolSize: 0,
						data: []
					}]
				};
				let data = [{
					value: 12345,
					upVal: 1
				}, {
					value: 12536,
					upVal: 2
				}, {
					value: 25632,
					upVal: 3
				}, {
					value: 45236,
					upVal: 4
				}, {
					value: 45876,
					upVal: 5
				}, {
					value: 52365,
					upVal: 6
				}, {
					value: 45213,
					upVal: 7
				}, {
					value: 23541,
					upVal: 8
				}, {
					value: 54768,
					upVal: 9
				}, {
					value: 35213,
					upVal: 10
				}, {
					value: 32412,
					upVal: 19
				}, {
					value: 53268,
					upVal: 30,

				}]
				lineOptions.series[0].data = data;
				this.lineOptions = {
					...lineOptions
				};
			},
			//标题切换
			topSelectChange(name, value) {
				console.log(name, value)
			},
		},
	};
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		padding: 10px;

		.leftView,
		.rightView {
			width: 400px;
		}

		>div {
			height: 950px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.centerView {
			flex: 1;
			color: #fff;

			.top {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;

				p:first-child {
					font-size: 20px;
					color: rgba(255, 255, 255, 0.80);
					display: flex;
					align-items: center;

					span {
						margin: 0 10px;
					}

					img {
						width: 21px;
						height: 20px;
					}

					img:last-child {
						transform: rotate(180deg);
					}
				}

				p:last-child {
					width: 600px;
					line-height: 36px;
					margin: 0 auto;
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 16px;
					font-weight: bold;
					background: url('~@/assets/sportsBank/fontBg.png') no-repeat;
					background-size: 100% 100%;
				}
			}

			.title {
				width: 180px;
				line-height: 34px;
				text-align: center;
				font-weight: 700;
				background: linear-gradient(90deg, rgba(8, 94, 236, 0.00) 0%, rgba(8, 94, 236, 0.00) 0%, rgba(8, 94, 236, 0.49) 100%, rgba(8, 94, 236, 0.25) 100%, rgba(8, 94, 236, 0.00) 100%);
				font-size: 16px;
				font-family: Source Han Sans CN, Source Han Sans CN-Bold;
				font-weight: 700;
				color: #ffffff;
				text-shadow: 0px 0px 6px 0px rgba(172, 241, 255, 0.70);
			}
		}

		.leftView {

			.top {
				.sportExchangeView {
					width: 100%;
					height: 300px;
					display: flex;
					justify-content: center;
					align-items: flex-start;
				}

				.legendList {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					.legendItem {
						width: 130px;
						line-height: 40px;
						display: flex;
						align-items: center;
						font-size: 16px;
						color: #fff;
						font-weight: 700;

						span {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}
				}
			}

		}
		// .rightView{
		// 	.top{
		// 		flex:1;
		// 		display: flex;
		// 		flex-direction: column;
		// 	}
		// 	.foot{
		// 		height:390px;
		// 	}
		// }
	}
</style>
