<template>
  <div id="centerMap">
    <div class="centerMapContainer">
      <div id="echartMap" ref="echartMap" class="echartMap"></div>
    </div>
  </div>
</template>

    <script>
import echarts from "echarts";
import "echarts-gl";
import service from "@/utils/request"

import wz from "../../../assets/source/wz.json";
// import jn from "../../../assets/source/jn.json";
export default {
  components: {},
  data() {
    return {
      mapdata: [],
      options: {
        // title: {
        //   text: "各区县用户总数及排名",
        //   left: "left",
        //   padding: [10, 0, 10, 30],
        //   textStyle: {
        //     color: "#fff",
        //     fontSize: 24,
        //   },
        // },
        grid: {
          // 让图表占满容器
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        tooltip: {
          trigger: "item",
          position: "top",
          formatter: "{b}<br/>{c}",
        },
        visualMap: {
          min: 0,
          max: 100,
          text: ["多", "少"], //两端的文本
          textStyle: {
            color: "#fff",
            fontSize: "14px",
          },
          realtime: false,
          calculable: true,
          itemWidth: 20, //图形的宽度，即长条的宽度。
          itemHeight: 90, //图形的高度，即长条的高度。
          align: "auto", //指定组件中手柄和文字的摆放位置.可选值为：‘auto’ 自动决定。‘left’ 手柄和label在右。‘right’ 手柄和label在左。‘top’ 手柄和label在下。‘bottom’ 手柄和label在上。
          left: "90%", //组件离容器左侧的距离,‘left’, ‘center’, ‘right’,‘20%’
          top: "20% ", //组件离容器上侧的距离,‘top’, ‘middle’, ‘bottom’,‘20%’
          right: "auto", //组件离容器右侧的距离,‘20%’
          bottom: "auto", //组件离容器下侧的距离,‘20%’
          orient: "vertical", //图例排列方向
          inRange: {
            color: [
              "#72BDF0",
              "#0A89EE",
              "#1A6ACB",
              "#0A50AA",
              "#0A3781",
              "#0A2353",
              "#0B2250",
              "#0D1D3B",
            ],
          },
          show: true,
        },
        geo: {
          // zoom: 1.8,
          layoutSize: "150%",
        },
        nameProperty: "adcode",
        series: [
          {
            type: "map",
            mapType: "ISD",
            roam: false, //禁止缩放
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontSize: 16,
                fontWeight: 600
              },
              emphasis: {
                show: true,
                color: "#fff",
              },
            },
            itemStyle: {
              //   emphasis: {
              //     label: { show: true },
              //     select: { color: "red" },
              //   },
            },
            select: {
              label: {
                // backgroundColor: '#999'
              }

            },
            data: [],
          },
        ],
      }
    };
  },
  mounted() {
    this.getEchartMap();
    this.getData();
  },
  methods: {
    getData() {
      const myThis = this;
      service.get('/synthesis/sportData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            const options = myThis.options;
            options.series[0].data = data.list.map((item) => {
              return { value: item.num, name: item.name, adcode: item.code.substr(0,6) }
            });
            console.log(options);
            this.$refs.echartMap.setOption(options, true)
          })
          .catch((response) => {
            console.log(response);
          })
    },
    getEchartMap() {
      echarts.registerMap("ISD", wz);
      var chart = echarts.init(document.getElementById("echartMap"));
      chart.setOption(this.options);
      var flag = true; // 为了做判断：当鼠标移动上去的时候，自动高亮就被取消
      var _this = this;
      var index = 0;
      // 鼠标移动上去的时候的高亮动画
      chart.on("mouseover", function (param) {
        flag = false;
        clearInterval(_this.startCharts);
        //取消之前高亮图形
        chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index,
        });

        index = param.dataIndex;
        //**解决文章所述问题**// //记录上一次选中值重新进入时取消高亮

        //高亮当前图形
        chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        });
        //显示tooltip
        chart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        });
      });

      //图标随窗口大小缩放
      window.addEventListener("resize", function () {
        chart.resize();
      });

      //自动高亮显示
      var chartHover = function () {
        var dataLen = 2;

        // 取消之前高亮的图形
        chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index,
        });
        index = (index + 1) % dataLen;

        // 高亮当前图形
        chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index,
        });
        // 显示 tooltip
        chart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        });
      };

      _this.startCharts = setInterval(chartHover, 5000);
      // 4、鼠标移出之后，恢复自动高亮
      chart.on("mouseout", function (param) {
        if (!flag) {
          _this.startCharts = setInterval(chartHover, 5000);
          flag = true;
        }
      });
    },
  },
};
</script>

    <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#centerMap {
  .centerMapContainer {
    // height: 262px;
    width: 100%;
    .echartMap {
      width: 80%;
      height: 600px;
      margin: 0 auto;
    }
    // padding: 20px 30px;
  }
}
</style>
