<template>
  <div class="CenterServerContainer">
    <ContentTop
      title="场地服务人次排行榜"
      :topOptionsProps="cityActiveOptionProps"
      :type="2"
    />
    <div class="rank-list" style="margin-top: 21px">
      <rank-list :listHeight="292" :tableTitle="tableTitle" :list="areaData" />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      // 市级协会赛事活动排名 top下拉
      cityActiveOptionProps: {
        selects: [
          {
            name: 'cityTopSelect',
            options: [{ value: 1, label: '红榜' }],
          },
          {
            name: 'rank2',
            options: [{ value: 2, label: '鹿城区' }],
          },
        ],
      },
      tableTitle: [
        {
          title: '排名',
          width: '210',
        },
        {
          title: '场地名称',
          val: 'first',
          width: '300',
        },
        {
          title: '设施数量',
          val: 'second',
          width: '300',
        },
      ],
      areaData: [
        {
          first: '温州龙舟中心',
          second: '89056',
        },
        {
          first: '温州体育中心',
          second: '81638',
        },
        {
          first: '瓯海体育中心',
          second: '74069',
        },
        {
          first: '温州奥体中心',
          second: '71556',
        },

        {
          first: '苍南体育中心',
          second: '68536',
        },
        {
          first: '瑞安体育中心',
          second: '58339',
        },
        {
          first: '泰顺体育中心',
          second: '50129',
        },
        {
          first: '乐清体育中心',
          second: '46558',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.CenterServerContainer {
  width: 100%;
  padding-bottom: 31px;
  //   padding: 0 68px 31px 68px;
}
</style>
