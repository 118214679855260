<template>
  <div class="RightBottomContainer">
    <ContentTop
      title="体育场地设施数量排行"
      :topOptionsProps="cityActiveOptionProps"
      :type="2"
    />
    <div class="rank-list" style="margin-top: 23px">
      <rank-list :listHeight="334" :tableTitle="tableTitle" :list="areaData" />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      // 市级协会赛事活动排名 top下拉
      cityActiveOptionProps: {
        selects: [
          {
            name: 'cityTopSelect',
            options: [{ value: 1, label: '红榜' }],
          },
          {
            name: 'rank2',
            options: [{ value: 2, label: '鹿城区' }],
          },
        ],
      },
      tableTitle: [
        {
          title: '排名',
          width: '80',
        },
        {
          title: '场地名称',
          val: 'first',
          width: '150',
        },
        {
          title: '设施数量',
          val: 'second',
          width: '150',
        },
      ],
      areaData: [
        {
          first: '温州体育中心',
          second: '180',
        },
        {
          first: '温州奥体中心',
          second: '160',
        },
        {
          first: '瓯海体育中心',
          second: '158',
        },

        {
          first: '温州龙舟中心',
          second: '150',
        },
        {
          first: '瑞安体育中心',
          second: '140',
        },
        {
          first: '苍南体育中心',
          second: '130',
        },
        {
          first: '乐清体育中心',
          second: '100',
        },
        {
          first: '泰顺体育中心',
          second: '90',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.RightBottomContainer {
}
</style>
