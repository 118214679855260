<template>
  <div id="leftCode">
    <div class="leftCodeContainer">
      <ContentTop title="运动码使用情况" :type="1" />
      <div class="item-chart-top">
        <Echart
          :options="options"
          id="bottomLeftChart1"
          height="190px"
          width="100%"
        ></Echart>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import Echart from '@/common/echart'
import ContentTop from '@/components/contentTop'
import service from "@/utils/request"
export default {
  components: {
    Echart,
    ContentTop,
  },
  data() {
    return {
      timing: null,
      options: {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#303030',
          borderColor: '#2DCDFF', // 修改字体颜色
          borderWidth: 1,
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            console.log(params)
            let res = ''
            res = `
                        <div style="padding:8px 12px;">
                          <div>运动码使用情况：</div>
                          <div style="font-size: 12px;padding-top: 6px">总数：${params[0]?.data}人</div>
                        </div>`
            return res
          },
        },
        grid: {
          top: '18%',
          left: '8%',
          right: '0%',
          bottom: '10%',
        },
        xAxis: {
          data: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
        },
        yAxis: [
          {
            name: '万人',
            // data: "value",
            type: 'value',
            axisLabel: {
              formatter: function (v) {
                return v / 10000
              },
            },
          },
        ],
        series: [
          {
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: 'rgba(8,253,254,0)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(8,253,254,1)',
                    },
                  ],
                  false
                ),
              },
            },
            data: [],
            type: 'bar',
            barWidth: 10,
          },
          {
            data: [],
            type: 'line',
            itemStyle: {
              color: '#056FEB',
            },
            lineStyle: {
              width: 2,
            },
          },
        ],
      },
    }
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/sportMonthData')
          .then(function (response) {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            const oneData = data.map((item) => item.num);
            myThis.options.series[0].data = oneData;
            myThis.options.series[1].data = oneData;
          })
          .catch(function (error) {
            console.log(error);
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
#leftCode {
  .leftCodeContainer {
    height: 262px;
    width: 100%;
    // padding: 20px 30px;
  }
}
</style>
