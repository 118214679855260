<template>
  <div class="centerBottomContainer">
    <div class="bottomItem" v-for="(item, index) in bottomData" :key="index">
      <div class="topWrapper">
        <div class="imgBox">
          <div class="imgInner">
            <img :src="item.icon" alt="" />
          </div>
        </div>
        <div class="countBox">
          <div class="top">
            <span class="topCount">{{ item.topCount }}</span>
            <span class="topLabel">{{ item.topLabel }}</span>
          </div>
          <div class="topTitle">{{ item.topTitle }}</div>
        </div>
      </div>
      <div class="bottomWrapper">
        <div class="rankBox">
          <div class="rankItem">
            <div>
              <span>{{ item.rankLeftCount }}</span
              ><span class="itemLabel">{{ item.rankLeftLabel }}</span>
            </div>
            <div class="rankTitle">{{ item.rankLeftTitle }}</div>
          </div>
          <div class="rankItem">
            <div>
              <span>{{ item.rankRightCount }}</span
              ><span class="itemLabel">{{ item.rankRightLabel }}</span>
            </div>
            <div class="rankTitle">{{ item.rankRightTitle }}</div>
          </div>
        </div>
        <div class="rankBox" style="margin-top: 8px">
          <div class="rankItem">
            <div>
              <span>{{ item.rateLeftCount }}%</span>
              <span
                  :class="item.rateLeftCount > 0 ? 'iconfont icon-shang' : 'iconfont icon-xiangxia3'"
                style="margin-left: 3px; font-size: 14px; color: #f13529"
              ></span>
            </div>
            <div class="rankTitle">上月</div>
          </div>
          <div class="rankItem">
            <div>
              <span>{{ item.rateRightCount }}%</span>
              <span
                :class="item.rateRightCount > 0 ? 'iconfont icon-shang' : 'iconfont icon-xiangxia3'"
                style="margin-left: 3px; font-size: 14px; color: #f13529"
              ></span>
            </div>
            <div class="rankTitle">上月</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import order from '../../../assets/sportFacilities/order_icon.png'
import serve from '../../../assets/sportFacilities/serve_icon.png'
import guide from '../../../assets/sportFacilities/guide.png'
import service from "@/utils/request";
export default {
  components: {},
  data() {
    return {
      timing: null,
      bottomData: [
        {
          icon: order,
          topCount: 201,
          topLabel: '单',
          topTitle: '场馆订单数量',
          rankLeftCount: 89,
          rankLeftLabel: '单',
          rankLeftTitle: '体育馆',
          rankRightCount: 112,
          rankRightLabel: '单',
          rankRightTitle: '运动场所',
          rateLeftCount: 16,
          rateRightCount: 8,
        },
        {
          icon: serve,
          topCount: 9086,
          topLabel: '人',
          topTitle: '赛事总服务人次',
          rankLeftCount: 5,
          rankLeftLabel: '次',
          rankLeftTitle: '本月赛事场数',
          rankRightCount: 9083,
          rankRightLabel: '人',
          rankRightTitle: '本月赛事服务人次',
          rateLeftCount: 12,
          rateLeftTitle: '上月',
          rateRightCount: 11,
          rateRightTitle: '上月',
        },
        {
          icon: guide,
          topCount: 237,
          topLabel: '人',
          topTitle: '指导员服务人次',
          rankLeftCount: 5,
          rankLeftLabel: '次',
          rankLeftTitle: '发布次数',
          rankRightCount: 12,
          rankRightLabel: '场',
          rankRightTitle: '指导员团课',
          rateLeftCount: 13,
          rateLeftTitle: '上月',
          rateRightCount: 9,
          rateRightTitle: '上月',
        },
      ],
    }
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      // 赛事总服务人次
      service.get('/synthesis/actServicePerson')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            let rateLeftCount = 0;
            let rateRightCount = 0;
            if(data.last_act_num == 0)
              rateLeftCount = (data.act_num - data.last_act_num)*100;
            else
              rateLeftCount = Math.ceil((data.act_num - data.last_act_num)*100 / data.last_act_num)
            if(data.last_month_service_num == 0)
              rateRightCount = (data.month_service_num - data.last_month_service_num)*100
            else
              rateRightCount = Math.ceil((data.month_service_num - data.last_month_service_num)*100 / data.last_month_service_num)
            const tmp = myThis.bottomData;
            tmp[1] = {
              icon: serve,
              topCount: data.service_num,
              topLabel: '人',
              topTitle: '赛事总服务人次',
              rankLeftCount: data.act_num,
              rankLeftLabel: '次',
              rankLeftTitle: '本月赛事场数',
              rankRightCount: data.month_service_num,
              rankRightLabel: '人',
              rankRightTitle: '本月赛事服务人次',
              rateLeftCount: rateLeftCount,
              rateLeftTitle: '上月',
              rateRightCount: rateRightCount,
              rateRightTitle: '上月',
            };
            myThis.bottomData = [...tmp];
            myThis.getOrderData();
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getOrderData() {
      const myThis = this;
      // 场馆订单数
      service.get('/synthesis/orderData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            let rateLeftCount = 0;
            let rateRightCount = 0;
            if(data.gymnasium_order_last_num == 0)
              rateLeftCount = (data.gymnasium_order_num - data.gymnasium_order_last_num)*100;
            else
              rateLeftCount = Math.ceil((data.gymnasium_order_num - data.gymnasium_order_last_num)*100 / data.gymnasium_order_last_num)
            if(data.sports_order_last_num == 0)
              rateRightCount = (data.sports_order_num - data.sports_order_last_num)*100
            else
              rateRightCount = Math.ceil((data.sports_order_num - data.sports_order_last_num)*100 / data.sports_order_last_num)
            const tmp = myThis.bottomData;
            tmp[0] = {
              icon: order,
              topCount: data.order_num,
              topLabel: '单',
              topTitle: '场馆订单数量',
              rankLeftCount: data.gymnasium_order_num,
              rankLeftLabel: '单',
              rankLeftTitle: '体育馆',
              rankRightCount: data.sports_order_num,
              rankRightLabel: '单',
              rankRightTitle: '运动场所',
              rateLeftCount: rateLeftCount,
              rateRightCount: rateRightCount,
            };
            myThis.bottomData = [...tmp];
            myThis.getInstructorData();
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getInstructorData() {
      const myThis = this;
      // 指导员服务人次
      service.get('/synthesis/instructorData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            let rateLeftCount = 0;
            let rateRightCount = 0;
            if(data.publish_last_num == 0)
              rateLeftCount = (data.publish_num - data.publish_last_num)*100;
            else
              rateLeftCount = Math.ceil((data.publish_num - data.publish_last_num)*100 / data.publish_last_num)

            if(data.league_class_last_num == 0)
              rateRightCount = (data.league_class_num - data.league_class_last_num)*100
            else
              rateRightCount = Math.ceil((data.league_class_num - data.league_class_last_num)*100 / data.league_class_last_num)
            const tmp = myThis.bottomData;
            tmp[2] = {
              icon: guide,
              topCount: data.person_num,
              topLabel: '人',
              topTitle: '指导员服务人次',
              rankLeftCount: data.publish_num,
              rankLeftLabel: '次',
              rankLeftTitle: '发布次数',
              rankRightCount: data.league_class_num,
              rankRightLabel: '场',
              rankRightTitle: '指导员团课',
              rateLeftCount: rateLeftCount,
              rateLeftTitle: '上月',
              rateRightCount: rateRightCount,
              rateRightTitle: '上月',
            };
            myThis.bottomData = [...tmp];
          })
          .catch((response) => {
            console.log(response)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.centerBottomContainer {
  width: 100%;
  text-align: center;
  // margin-bottom: 33px;
  // margin-bottom: 21px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bottomItem {
    padding: 11px 0 9px;
    background-image: url('~@/assets/sportFacilities/bottom_bg.png');
    background-size: 100% 100%;
    width: 260px;
    .topWrapper {
      display: flex;
      padding-top: 11px;
      padding-left: 38px;
      .imgBox {
        width: 39px;
        height: 39px;
        border: 1px solid rgba(255, 255, 255, 0.24);
        border-radius: 50%;
        text-align: center;
        .imgInner {
          width: 24px;
          height: 24px;
          margin: 0 auto;
          margin-top: 7px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .countBox {
        margin-left: 14px;
        text-align: left;
        .top {
          .topCount {
            color: #d4d739;
            font-size: 20px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
          }
          .topLabel {
            margin-left: 4px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.7);
          }
        }
        .topTitle {
          margin-top: 4px;
          font-weight: 700;
          text-align: left;
        }
      }
    }
    .bottomWrapper {
      margin-top: 5px;
      font-weight: 700;
      font-size: 14px;
      .rankBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .rankItem {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .itemLabel {
            margin-left: 3px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
          }
          .rankTitle {
            margin-top: 2px;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}
</style>
