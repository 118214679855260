<template>
  <div class="d-flex CenterScoreContainer">
    <div class="leftBox">
      <div class="topDes">温州羽毛球场馆</div>
      <div class="topRate">
        <div class="innerDes">本年度最受喜欢的场馆</div>
      </div>
    </div>
    <div class="rightBox">
      <div class="topDes">温州羽毛球场馆</div>
      <div class="topRate">
        <div class="innerDes">本年度评价最高的场馆</div>
      </div>
    </div>
  </div>
</template>
                
                <script>
import AccumulateTip from "@/components/accumulateTip";
export default {
  components: {
    AccumulateTip,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
                
                <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.CenterScoreContainer {
    align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: 10px;

  .leftBox {
    .topDes {
      font-size: 40px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: center;
      color: #00ffff;
      height: 40px;
      margin-bottom: 10px;
    }
    .topRate {
      width: 401px;
      height: 316px;
      background-image: url("~@/assets/sportFacilities/popu_icon.png");
      background-size: cover;
      .innerDes {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #00ffff;
        text-align: center;
      }
    }
  }

  .rightBox {
    .topDes {
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: center;
      color: rgba(255, 255, 255, 0.85);
      height: 40px;
      margin-bottom: 4px;
    }
    .topRate {
      width: 343px;
      height: 270px;
      background-image: url("~@/assets/sportFacilities/popu_icon.png");
      background-size: cover;
      .innerDes {
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: rgba(239, 255, 255, 0.9);
      }
    }
  }
}
</style>
                