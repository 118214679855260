<template>
  <div class="RightTopContainer">
    <ContentTop
      title="百姓健身房人流量排行"
      :type="2"
      :topOptionsProps="sportInfo"
      @topSelectChange="topRankSelectChange"
    />
    <div class="rank-list" style="margin-top: 14px">
      <rank-list :listHeight="280" :tableTitle="tableTitle" :list="areaData" />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
import service from "@/utils/request";
export default {
  components: {
    ContentTop,
    rankList,
  },
  data() {
    return {
      tableTitle: [
        {
          title: '排名',
          width: '100',
        },
        {
          title: '场地名',
          val: 'first',
          width: '200',
        },
        {
          title: '服务人次',
          val: 'second',
          width: '100',
        },
      ],
      sportInfo: {
        selects: [
          {
            name: 'one',
            options: this.$store.state.areaList
          },
        ],
      },
      areaData: [],
      timing: null,
      getTrafficRankingDataCode: '',
    }
  },
  mounted() {
    this.getTrafficRankingData('');
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getTrafficRankingData(this.getTrafficRankingDataCode);
      }, 5000);
    },
    getTrafficRankingData(code) {
      // 百姓健身房人流量排行
      const myThis = this;
      service.get('/gym/trafficRanking',{params: {code: code}})
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.areaData = data.map((item) => {
              return {
                first: item.name,
                second: item.service_num,
              }
            });
          })
          .catch((response) => {
            console.log(response)
          })
    },
    topRankSelectChange(name, value) {
      this.getTrafficRankingDataCode = value;
      this.getTrafficRankingData(value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.RightTopContainer {
  .searchIpt {
    width: 100%;
    height: 40px;
    margin: 18px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    input {
      flex: 1;
      padding-left: 40px;
      line-height: 30px;
      background: rgba(86, 148, 255, 0.2);
      border-radius: 2px;
      //backdrop-filter: blur(20px);
      font-size: 14px;
      color: rgba(255, 255, 255, 0.56);
      border: none;
    }

    input::placeholder {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.56);
    }

    .text-icon {
      position: absolute;
      left: 10px;
      top: 10px;
      color: #fff;
    }

    .rank-radio {
      //width:100px;
    }
  }
}
</style>
