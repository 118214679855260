<template>
  <div class="container">
    <div class="siteWrapper">
      <div class="siteLeft d-flex flex-column">
        <LeftCount />
        <LeftInfo3 />
        <LeftDisplay2 />
      </div>
      <div class="siteCenter">
        <CenterTop />
        <div class="d-flex centerWrapper">
          <CenterStar />
<!--          <CenterData />-->
        </div>
<!--        <CenterBottom />-->
      </div>
      <div class="siteRight">
        <RightTop />
        <RightCenter2 />
        <RightBottom />
      </div>
    </div>
  </div>
</template>

      <script>
import drawMixin from "@/utils/drawMixin";
import { formatTime } from "@/utils/index.js";
import LeftCount from "./components/LeftCount";
import LeftDisplay2 from "./components/LeftDisplay2";
import LeftInfo3 from "./components/LeftInfo3";
import RightTop from "./components/RightTop";
import RightCenter2 from "./components/RightCenter2";
import RightBottom from "./components/RightBottom";
import CenterTop from "./components/CenterTop";
import CenterStar from "./components/CenterStar";
import CenterBottom from "./components/CenterBottom";
import CenterData from "./components/CenterData";

export default {
  mixins: [drawMixin],
  components: {
    LeftCount,
    LeftDisplay2,
    LeftInfo3,
    RightTop,
    RightCenter2,
    RightBottom,
    CenterTop,
    CenterStar,
    CenterBottom,
    CenterData,
  },
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
    };
  },

  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

      <style lang="scss" scoped>
@import "../../assets/scss/index.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 16px;
  & > div {
    height: 950px;
  }
  .siteWrapper {
    width: 100%;
    display: flex;
    // padding: 0 49px;
    .siteLeft {
      width: 436px;
      justify-content: space-between;
      // border: 1px solid #fafafa;
    }
    .siteCenter {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 51px;
      // background: gray;
      .centerWrapper {
        & > div {
          flex: 1;
        }
      }
    }
    .siteRight {
      width: 436px;
      // background: red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
