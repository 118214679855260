<template>
  <div class="RightTopContainer">
    <ContentTop title="健身房信息" :type="2" />

    <div style="margin-top: 13px">
      <AccumulateTip
        iconName="gym_icon"
        :width="60"
        label="健身房总数"
        :count="totalNum"
        :countMargin="6"
        unit="个"
      />
    </div>
    <div style="margin-top: 21px">
      <!-- <Sliders
        @sliderChange="sliderChange"
        :options="optData"
        :textWidth="80"
        :isHasRight="true"
        :isHasTop="false"
        :rightTextWidth="95"
      /> -->
      <div class="data-wrap">
        <div class="item item1">
          <div class="label">一类健身房</div>
          <div class="value">183个</div>
        </div>
        <div class="item item2">
          <div class="label">农村健身房</div>
          <div class="value">272个</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentTop from "@/components/contentTop";
import Sliders from "@/components/Sliders";
import Radio from "@/components/Radio";
import AccumulateTip from "@/components/accumulateTip";
import service from "@/utils/request";
export default {
  components: {
    ContentTop,
    Sliders,
    Radio,
    AccumulateTip,
  },
  data() {
    return {
      totalNum: 0,
      optData: [],
      timing: null,
    };
  },
  mounted() {
    this.getQuantityDataData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getQuantityDataData();
      }, 5000);
    },
    getQuantityDataData() {
      // 健身房信息
      const myThis = this;
      service
        .get("/gym/quantityData")
        .then((response) => {
          if (response.code != 200) {
            console.log(response.message);
            return false;
          }
          const data = response.data;
          const all =
            data.one_class_num +
            data.two_class_num +
            data.city_num +
            data.village_num;
          let data1 = 0;
          let data2 = 0;
          let data3 = 0;
          let data4 = 0;
          if (all != 0) {
            data1 = ((data.one_class_num / all) * 100).toFixed(1);
            data2 = ((data.two_class_num / all) * 100).toFixed(1);
            data3 = ((data.city_num / all) * 100).toFixed(1);
            data4 = ((data.village_num / all) * 100).toFixed(1);
          }
          // myThis.totalNum = data.num;
          myThis.totalNum = 455;
          myThis.optData = [
            { value: data1, label: "一类总数", rightText: data1 + "%" },
            { value: data2, label: "二类总数", rightText: data2 + "%" },
            { value: data3, label: "城市总数", rightText: data3 + "%" },
            { value: data4, label: "农村总数", rightText: data4 + "%" },
          ];
        })
        .catch((response) => {
          console.log(response);
        });
    },
    sliderChange(index, value) {
      console.log(index, value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.RightTopContainer {
}

.data-wrap {
  padding: 24px 32px;
  box-sizing: border-box;
  border: 1px solid rgb(19, 92, 128);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  .item {
  border-radius: 8px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(19, 41, 82);
    .label {
      color: white;
    }
    .value {
      margin-top: 12px;
      color: rgb(91, 183, 197);
      font-size: 22px;
    }
  }
}
</style>
