<template>
  <div class="CenterAreaContainer">
    <div>
      <ContentTop title="地区使用人数" :type="3" />
    </div>
    <div class="columnBox">
      <div
        class="columnItem"
        v-for="(item, index) in rateList"
        :key="index"
        :style="`background: ${
          index > 4 ? bottomColor : index > 0 ? centerColor : topColor
        };width:${index > 4 ? '92.1%' : '100%'};`"
      >
        <div class="columnIndex">{{ `Top${index + 1}` }}</div>
        <div>{{ item.name }}</div>
        <div class="countBox">
          <span>{{ item.value }}</span
          ><span
            :class="`iconfont icon-${item.status ? 'shang' : 'xiangxia3'}`"
            :style="`font-size: 16px; color: ${
              item.status ? '#07f3f5' : '#f1ae29'
            } ; margin-left: 2px;display: none;`"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContentTop from '@/components/contentTop'
export default {
  components: {
    ContentTop,
  },
  props: {
    rateList: {
      type: Array,
      default: ()=>([])
    },
  },
  data() {
    return {
      topColor: `linear-gradient(89deg,#de7947 0%, rgba(238,106,52,0.00) 100%);`,
      centerColor: `linear-gradient(89deg,#d69e2d 0%, rgba(214,158,45,0.00) 100%);`,
      bottomColor: `linear-gradient(89deg,rgba(8,94,236,0.72) 0%, rgba(8,94,236,0.72) 2%, rgba(8,94,236,0.00) 100%);`,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.CenterAreaContainer {
  width: 266px;
  .columnBox {
    margin-top: 9px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 200px;
    overflow-y: auto;
    &.no-right {
      padding-right: 18px;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #85ace9;
    }

    &::-webkit-scrollbar-track {
      background-color: #020927;
    }
    .columnItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      //   width: 100%;
      padding: 3px;
      font-size: 14px;
      font-weight: 700;
      border-top-left-radius: 13px;
      border-bottom-left-radius: 13px;
      //   background: #999;
      .columnIndex {
        padding-left: 30px;
      }
      .countBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>
