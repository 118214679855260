<template>
  <div id="bubbleImg"></div>
</template>

<script>
import echarts from 'echarts'
export default {
  data() {
    return {
      poparray: [
        //气泡图的数据，label是字，amount是大小
        { label: "手球", amount: 100 },
        { label: "举重", amount: 70 },
        { label: "垒球", amount: 30 },
        { label: "乒乓球", amount: 50 },
        { label: "篮球", amount: 70 },
        { label: "游泳", amount: 100 },
        { label: "羽毛球", amount: 2 },
        { label: "跑步", amount: 70 },
        { label: "排球", amount: 70 },
        { label: "高尔夫", amount: 70 },
        { label: "短跑", amount: 70 },
        { label: "网球", amount: 70 },
        { label: "足球", amount: 70 },
        { label: "武术", amount: 70 }
      ]
    }
  },
  mounted() {
    this.bubbleData(this.poparray, ["label", "amount"], "bubbleImg");
  },
  methods: {
    bubbleData(data, format, dom) {
      let [maxValue, temp] = [0, []];
      data.forEach((item) => {
        temp.push(item[format[1]]);
      });
      maxValue = Math.max.apply(null, temp);

      // 气泡颜色数组
      let color = ["rgba(252,188,2,0.80)", "rgba(109,197,242,0.80)", "rgba(70,189,11,0.80)", "rgba(186,212,25,0.45)", "rgba(186,212,25,0.28)"];
      // 气泡颜色备份
      let bakeColor = [...color];
      // 气泡数据
      let bubbleData = [];
      // 气泡基础大小
      let basicSize = 70;
      // 节点之间的斥力因子,值越大,气泡间距越大
      let repulsion = 380;
      // 根据气泡数量配置基础大小和斥力因子（以实际情况进行适当调整，使气泡合理分布）
      if (data.length >= 5 && data.length < 10) {
        basicSize = 50;
        repulsion = 230;
      }
      if (data.length >= 10 && data.length < 20) {
        basicSize = 40;
        repulsion = 60;
      } else if (data.length >= 20) {
        basicSize = 30;
        repulsion = 35;
      }

      // 填充气泡数据数组bubbleData
      for (let item of data) {
        // 确保气泡数据条数少于或等于气泡颜色数组大小时，气泡颜色不重复
        if (!bakeColor.length) bakeColor = [...color];
        let colorSet = new Set(bakeColor);
        let curIndex = Math.round(Math.random() * (colorSet.size - 1));
        let curColor = bakeColor[curIndex];
        colorSet.delete(curColor);
        bakeColor = [...colorSet];
        // 气泡大小设置
        let size = (item[format[1]] * basicSize * 2) / maxValue;
        if (size < basicSize) size = basicSize;

        bubbleData.push({
          name: item[format[0]],
          value: item[format[1]],
          symbolSize: size,
          draggable: false,
          itemStyle: {
            normal: { color: curColor },
          },
        });
      }

      let bubbleId = document.getElementById(dom);
      let bubbleChart = echarts.init(bubbleId);
      let bubbleOptions = {
        backgroundColor: "rgba(0,0,0,0)",
        animationEasingUpdate: "bounceIn",
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              repulsion: repulsion,
              edgeLength: 10,
            },
            // 是否开启鼠标缩放和平移漫游
            roam: false,
            label: { normal: { show: true } },
            data: bubbleData,
          },
        ],
      };
      bubbleChart.setOption(bubbleOptions);
    },
  }
}

</script>

<style scoped>
#bubbleImg {
  width: 100%;
  height: 100%;
}
</style>