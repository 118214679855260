<template>
	<div class="container">
		<div class="d-flex flex-column left-content">
			<div class="d-flex flex-column rank-wrapper">
				<ContentTop title="体育社会组织信息" :type="2" :topOptionsProps="sportInfo"
					@topSelectChange="topSelectChange" />
				<div style="width:100%;height:300px;display: flex;justify-content: center;align-items: flex-end;">
          <Echart
            :options="LeftChartOptions"
            id="LeftChart1"
            height="280px"
            width="100%"
          ></Echart>
				</div>
			</div>
			<div class="d-flex flex-column data-wrapper">
				<ContentTop title="运动码" :type="2" :topOptionsProps="sportCode" @topSelectChange="topSportCodeSelectChange" />
				<div class="data-list">
					<rank-list :listWidth="400" :listHeight="555" :tableTitle="leftTableTitle" :list="leftTableList"/>
				</div>
			</div>

		</div>
		<!-- 中间数据 -->
		<div class="center">
			<div class="top">
				<div class="topItem">
					<img src="@/assets/matchActivity/matchTotal.png">
					社团总数:
					<p> <span>{{centerData.business_num || 0}}</span> <span>个</span></p>
				</div>
				<div class="topItem">
					<img src="@/assets/matchActivity/matchServer.png">
					社团总人数:
					<p> <span>{{centerData.member_num || 0}}</span> <span>人</span></p>
				</div>
			</div>
			<div class="centerView">
				<div class="left">
					<div class="leftBorder borderView">
						<span class="squareBox"></span>
						<p class="title">指导员人数</p>
						<p class="num">{{centerData.zdy_num || 0}}人</p>
					</div>
					<div class="leftBorder borderView">
						<span class="squareBox"></span>
						<p class="title">服务人次</p>
						<p class="num">{{centerData.service_num || 0}}人</p>
					</div>
					<div class="leftBorder borderView">
						<span class="squareBox"></span>
						<p class="title">发布赛事</p>
						<p class="num">{{centerData.saishi_num || 0}}场</p>
					</div>
          <div class="leftBorder borderView">
            <span class="squareBox"></span>
            <p class="title">发布培训</p>
            <p class="num">{{centerData.peixun_num || 0}}场</p>
          </div>
				</div>
				<div class="right">
					<div class="rightBorder borderView">
						<span class="squareBox"></span>
						<p class="title">党员人数</p>
						<p class="num">{{centerData.party_num || 0}}人</p>
					</div>
					<div class="rightBorder borderView">
						<span class="squareBox"></span>
						<p class="title">发布活动</p>
						<p class="num">{{centerData.huodong_num || 0}}场</p>
					</div>
					<div class="rightBorder borderView">
						<span class="squareBox"></span>
						<p class="title">社区服务</p>
						<p class="num">{{centerData.shequ_num || 0}}场</p>
					</div>
          <div class="rightBorder borderView">
            <span class="squareBox"></span>
            <p class="title">开展党建</p>
            <p class="num">{{centerData.dangjian_num || 0}}场</p>
          </div>
				</div>
			</div>
<!--			<div class="footView">-->
<!--				<div class="footItem" v-for="(item,index) in footViewList">-->
<!--					<p>-->
<!--						<span>{{item.name}}</span>-->
<!--						<span>{{item.num}}</span>-->
<!--					</p>-->
<!--					<progress-bar size="big" :val="item.val" />-->
<!--				</div>-->
<!--			</div>-->
		</div>
		<!-- 右边数据 -->
		<div class="rigth-content">
<!--			<div class="rightTop">-->
<!--				<ContentTop title="赛事级别排名" :type="2" />-->
<!--				<video-player style="width:400px;height:180px;margin:10px 0;" :options="playerOptions" />-->
<!--			</div>-->
			<div class="rightCenter">
				<ContentTop title="党组织信息" :type="2" />
				<div class="partyTitle" style="margin-top: 20px">
					<img src="@/assets/sportsSociety/party.png" alt="">
					<div class="partyContent">
						<p>党组织数量</p>
						<div class="line" />
						<p>{{partyData.total}}个</p>
					</div>
				</div>
				<div class="partyChartView">
					<div class="leftParty">
						<p>{{ partyData.extensive }}</p>
						<p>拓展型</p>
					</div>
					<div class="rightParty">
						<p>{{ partyData.entity }}</p>
						<p>实体型</p>
					</div>
				</div>
			</div>
      <div class="d-flex flex-column data-wrapper">
        <ContentTop title="累计服务人次" :type="2" :topOptionsProps="servicePeople" @topSelectChange="topPeopleSelectChange" />
        <div class="data-list">
          <rank-list :listWidth="400" :listHeight="555" :tableTitle="rightTableTitle" :list="rightTableList"/>
        </div>
      </div>
<!--			<div class="rightFoot">-->
<!--				<ContentTop title="百姓健身房信息" :type="2" />-->
<!--				<div class="data-list">-->
<!--					<rank-list :listWidth="400" :listHeight="240" :tableTitle="gymInfoTitle" viewType="us" :list="gymInfoList" />-->
<!--				</div>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
	import ContentTop from '@/components/contentTop';
	import Radio from '@/components/Radio';
	import rankList from "@/components/rankList";
	import progressBar from "@/components/progressBar"
  import service from "@/utils/request";
  import Echart from '@/common/echart'
  import echarts from 'echarts'
	import {
		videoPlayer
	} from 'vue-video-player'
	import 'video.js/dist/video-js.css'
	export default {
		components: {
			ContentTop,
			Radio,
			rankList,
			progressBar,
			videoPlayer,
      Echart
		},
		data() {
			return {
        centerData: {},
        LeftChartOptions: {
          tooltip: {
            trigger: 'item',
            formatter: '{a}<br/>{b}'
          },
          series: [
            {
              name: '体育社会组织信息',
              type: 'pie',
              radius: '80%',
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
              data: [],
              emphasis: {
                label: {
                  fontSize: 16
                },
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
				playerOptions: {
					controls: true,
					playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
					autoplay: false, // 如果true,浏览器准备好时开始回放。
					muted: true, // 默认情况下将会消除任何音频。
					loop: true, // 导致视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
						src: 'https://sport-bucket-1.oos-cn.ctyunapi.cn/ydm/video/video.mp4'//require('@/assets/sportsSociety/video.mp4') // url地址
					}],
					aspectRatio: '16:9',
					poster: require('@/assets/sportsSociety/video.png'), // 你的封面地址
					notSupportedMessage: '数据加载失败', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: false // 全屏按钮
					}
				},
				sportInfo: { // 体育社会组织信息
					selects: [{
						name: 'one',
            options: this.$store.state.areaList
					}]
				},
				sportCode: { // 运动码
					selects: [{
						name: 'one',
            options: this.$store.state.areaList
					}]
				},
        servicePeople: { // 累计服务人次
          selects: [{
            name: 'one',
            options: this.$store.state.areaList
          }]
        },
				rankRadioProps: {
					name: 'rankRadio',
					width: 110,
					options: [{
							value: 'distinguish',
							label: '区'
						},
						{
							value: 'county',
							label: '县'
						},
						{
							value: 'market',
							label: '市'
						}
					]
				},
				leftOptions: {},
				//左中表头
				leftTableTitle: [{
					title: '排名',
					width: '80'
				}, {
					title: '社团名称',
					val: 'first',
					width: '200'
				}, {
					title: '使用运动码人',
					val: 'second',
					width: '120'
				}],
        rightTableTitle: [{
          title: '排名',
          width: '80'
        }, {
          title: '社团名称',
          val: 'first',
          width: '200'
        }, {
          title: '累计服务人次',
          val: 'second',
          width: '120'
        }],
				leftTableList:[],
				rightTableList:[],
				//中下信息
				footViewList: [{
					name: '进驻百姓健身房数量',
					num: '16个',
					val: 70
				}, {
					name: '指导员发布课程的人数',
					num: '96个',
					val: 10
				}, {
					name: '活动人数',
					num: '356个',
					val: 70
				}, {
					name: '进社区结对人数',
					num: '58个',
					val: 20
				}, {
					name: '社团数量',
					num: '125个',
					val: 80
				}, {
					name: '发布活动人数',
					num: '25个',
					val: 90
				}, {
					name: '社团指导员人数',
					num: '18个',
					val: 85
				}],
				//右下表头
				gymInfoTitle: [{
					title: '健身房名称',
					val: 'first',
					width: '240'
				}, {
					title: '入住人数',
					val: 'second',
					width: '100'
				}, {
					title: '入住率',
					val: 'three',
					width: '100'
				}],
				//右下数据
				gymInfoList:[{
					first: '温州市洞头区小朴社区百姓健身房',
					second: '95',
					three: '90%',
				},{
					first: '温州市公安局机场区百姓健身房',
					second: '105',
					three: '89%',
				},{
					first: '洞头鹿西岛百姓健身房',
					second: '113',
					three: '88%',
				},{
					first: '五马街道墨池社区百姓健身房',
					second: '88',
					three: '86%',
				},{
					first: '苍南灵溪百姓健身房',
					second: '65',
					three: '80%',
				},{
					first: '吴桥社区百姓健身房',
					second: '66',
					three: '78%',
				},{
					first: '三溪文化宫百姓健身房',
					second: '99',
					three: '76%',
				}],
        partyData: {
          total: 0,
          entity: 0,
          extensive: 0
        },
        timing: null,
        getBusinessLevelDataCode: '',
        getBusinessMemberRankingDataCode: '',
        getBusinessServiceDataCode: '',
			}
		},
    mounted() {
      this.getBusinessLevelData('');
      this.getBusinessMemberRankingData('');
      this.getBusinessPartyData();
      this.getBusinessBasicInfoData();
      this.getBusinessServiceData('');
      this.timeFn();
    },
    beforeDestroy() {
      clearInterval(this.timing);
    },
    methods: {
      timeFn() {
        this.timing = setInterval(() => {
          this.getBusinessLevelData(this.getBusinessLevelDataCode);
          this.getBusinessMemberRankingData(this.getBusinessMemberRankingDataCode);
          this.getBusinessPartyData();
          this.getBusinessBasicInfoData();
          this.getBusinessServiceData(this.getBusinessServiceDataCode);
        }, 5000);
      },
      getBusinessBasicInfoData() {
        // 基础数据（屏幕中间）
        const myThis = this;
        service.get('/business/basicInfo')
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              myThis.centerData = data;
            })
            .catch((response) => {
              console.log(response)
            })
      },
      getBusinessLevelData(code) {
        // 体育社会组织信息
        const myThis = this;
        service.get('/business/evaLevel',{params: {code: code}})
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              myThis.LeftChartOptions.series[0].data = [
                {value: data['3A'], name: '3A: '+data['3A']+'个社团'},
                {value: data['4A'], name: '4A: '+data['4A']+'个社团'},
                {value: data['5A'], name: '5A: '+data['5A']+'个社团'},
              ]
            })
            .catch((response) => {
              console.log(response)
            })
      },
      getBusinessMemberRankingData(code) {
        // 运动码使用人数
        const myThis = this;
        service.get('/business/memberRanking',{params: {code: code}})
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              myThis.leftTableList = data.map((item) => {
                return {
                  first: item.org_com,
                  second: item.num
                }
              })
            })
            .catch((response) => {
              console.log(response)
            })
      },
      getBusinessPartyData() {
        // 党组织数量
        const myThis = this;
        service.get('/business/partyData')
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              myThis.partyData = {
                total: data.num,
                entity: data.entity_num,
                extensive: data.extensive_num
              }
            })
            .catch((response) => {
              console.log(response)
            })
      },
      getBusinessServiceData(code) {
        // 累计服务人次
        const myThis = this;
        service.get('/business/serviceData',{params: {code: code}})
            .then((response) => {
              if(response.code != 200) {
                console.log(response.message);
                return false;
              }
              const data = response.data;
              myThis.rightTableList = data.map((item) => {
                return {
                  first: item.org_com,
                  second: item.num
                }
              })
            })
            .catch((response) => {
              console.log(response)
            })
      },
			topSelectChange(name, value) {
        this.getBusinessLevelDataCode = value;
        this.getBusinessLevelData(value);
			},
      topSportCodeSelectChange(name, value) {
        this.getBusinessMemberRankingDataCode = value;
        this.getBusinessMemberRankingData(value);
			},
      topPeopleSelectChange(name, value) {
        this.getBusinessServiceDataCode = value;
        this.getBusinessServiceData(value);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		padding-top: 20px;

		.left-content,
		.rigth-content {
			width: 400px;
		}

		.center {
			flex: 1;
		}
	}

	.left-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.data-wrapper {
			margin-top: 10px;
			flex: 1;
			display: flex;
			flex-direction: column;

			.searchIpt {
				width: 100%;
				height: 40px;
				margin: 10px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;

				input {
					flex: 1;
					padding-left: 40px;
					line-height: 30px;
					background: rgba(86, 148, 255, 0.20);
					border-radius: 2px;
					//backdrop-filter: blur(20px);
					font-size: 14px;
					color: rgba(255, 255, 255, 0.56);
					border: none;
				}

				input::placeholder {
					font-size: 14px;
					color: rgba(255, 255, 255, 0.56);
				}

				.text-icon {
					position: absolute;
					left: 10px;
					top: 10px;
					color: #fff;
				}

				.rank-radio {
					//width:100px;
				}
			}

			.data-list {
				width: 100%;
				flex: 1;
				margin-top: 10px;
			}
		}

		.leftBottomView {
			.title {
				width: 126px;
				line-height: 34px;
				text-align: center;
				font-weight: 700;
				background: linear-gradient(90deg, rgba(8, 94, 236, 0.00) 0%, rgba(8, 94, 236, 0.00) 0%, rgba(8, 94, 236, 0.49) 100%, rgba(8, 94, 236, 0.25) 100%, rgba(8, 94, 236, 0.00) 100%);
				margin-top: 10px;
				font-size: 16px;
				font-family: Source Han Sans CN, Source Han Sans CN-Bold;
				font-weight: 700;
				color: #ffffff;
				text-shadow: 0px 0px 6px 0px rgba(172, 241, 255, 0.70);
			}

			.cardList {
				width: 400px;
				height: 240px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: space-between;
				color: #fff;

				.cardItem {
					width: 190px;
					height: 91px;
					display: flex;
					align-items: center;
					padding: 0 10px;
					background: rgba(255, 59, 164, 0.23);
					border: 1px solid rgba(255, 255, 255, 0.23);
					border-radius: 4px;

					.imgBox {
						width: 45px;
						height: 45px;
						margin-right: 10px;
						background: rgba(255, 255, 255, 0.30);
						border-radius: 2px;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							width: 32px;
							height: 32px;
						}
					}

					.fontBox {
						height: 54px;

						display: flex;
						flex-direction: column;
						justify-content: space-between;

						p:first-child {
							font-size: 16px;
						}

						p:last-child {
							font-size: 24px;
							font-weight: bold;
						}
					}

				}
			}
		}

	}

	.center {
		display: flex;
		flex-direction: column;
		padding: 0 20px;

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #fff;

			img {
				width: 66px;
				height: 66px;
				margin-right: 20px;
			}

			.topItem {
				display: flex;
				align-items: center;
				font-size: 22px;
				font-weight: 700;

				p {

					display: flex;
					margin-top: 10px;

					span:first-child {
						font-size: 40px;
						font-weight: bold;
						margin: 0 10px;
					}

					span:last-child {
						font-weight: 300;
						font-size: 20px;
						align-items: flex-end;
					}
				}
			}
		}

		.centerView {
			width: 100%;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: url('~@/assets/sportsSociety/center.png') center no-repeat;

			.left,
			.right {
				height: 80%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
			}

			.left {
				.borderView:first-child {
					.squareBox {
						background-color: #F7A600;
					}

					.title {
						color: #F7A600;
					}
				}

				.borderView:nth-child(2) {
					.squareBox {
						background-color: #45BD0A;
					}

					.title {
						color: #45BD0A;
					}
				}

        .borderView:nth-child(3) {
          .squareBox {
            background-color: #ffd75d;
          }

          .title {
            color: #ffd75d;
          }
        }

				.borderView:last-child {
					.squareBox {
						background-color: #E65406;
					}

					.title {
						color: #E65406;
					}
				}
			}

			.right {
				.borderView:first-child {
					.squareBox {
						background-color: #896AFF;
					}

					.title {
						color: #896AFF;
					}
				}

				.borderView:nth-child(2) {
					.squareBox {
						background-color: #008ED8;
					}

					.title {
						color: #008ED8;
					}
				}

        .borderView:nth-child(3) {
          .squareBox {
            background-color: #6bd6ff;
          }

          .title {
            color: #6bd6ff;
          }
        }

				.borderView:last-child {
					.squareBox {
						background-color: #00FFFF;
					}

					.title {
						color: #00FFFF;
					}
				}
			}
			.borderView {
				width: 230px;
				height: 123px;
				border-radius: 2px;
				padding: 10px;
				color: #fff;
				font-weight: 700;
				border: 1px solid;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;

				.squareBox {
					width: 15px;
					height: 15px;
					border-radius: 50%;
					position: absolute;
					top: 10px;
				}

				.title {

					font-size: 20px;
				}

				.num {
					font-size: 32px;
				}
			}

			.leftBorder {
				border-image: linear-gradient(90deg, #ffffff, rgba(255, 255, 255, 0.00) 100%) 1 1;

				.squareBox {
					left: 10px;
				}
			}

			.rightBorder {
				border-image: linear-gradient(90deg, rgba(255, 255, 255, 0.00), #ffffff 100%) 1 1;

				.squareBox {
					right: 10px;
				}
			}
		}

		.footView {
			width: 100%;
			height: 270px;
			background: url('~@/assets/boxBorder.png') no-repeat;
			background-size: 100% 100%;
			display: flex;
			flex-wrap: wrap;
			padding: 30px 30px 30px 0;

			.footItem {
				width: 50%;
				padding-left: 30px;
				display: flex;
				flex-direction: column;

				p {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #fff;
					font-weight: 700;
					margin-bottom: 20px;

					span:last-child {
						font-size: 16px;
					}

					span:last-child {
						font-size: 22px;
					}
				}
			}
		}
	}

	.rigth-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.rightTop {
			width: 100%;
			display: flex;
			flex-direction: column;
		}
    .data-list {
      width: 100%;
      flex: 1;
      margin-top: 10px;
    }
		.rightCenter {
			display: flex;
			flex-direction: column;

			.partyTitle {
				display: flex;
				align-items: center;

				>img {
					width: 80px;
					height: 80px;
					margin-right: 10px;
				}

				.partyContent {
					flex: 1;
					height: 80px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					font-weight: 700;
					color: #fff;
					font-size: 18px;

					.line {
						position: relative;
						width: 100%;
						height: 4px;
						background-color: #00FFFF;
					}

					.line::after {
						content: '';
						width: 0;
						height: 0;
						border-top: 10px solid transparent;
						border-left: 20px solid #00FFFF;
						border-bottom: 10px solid transparent;
						position: absolute;
						right: -8px;
						top: -8px;
					}

					p:last-child {
						width: 100%;
						font-size: 24px;
						color: #00FFFF;
						padding-left: 20px;
						line-height: 34px;
						background: linear-gradient(90deg, #0e4d61, rgba(14, 77, 97, 0.30) 100%);
					}
				}
			}

			.partyChartView {
				width: 100%;
				height: 180px;
				margin: 10px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-weight: 700;

				>div {
					width: 180px;
					height: 180px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}

				.leftParty {
					background: url('~@/assets/sportsSociety/leftParty.png') no-repeat;
					background-size: cover;
					font-size: 30px;
					color: #00ffff;

					p:last-child {
						color: #fff;
						font-size: 16px;
						margin-top: 10px;
					}
				}

				.rightParty {
					background: url('~@/assets/sportsSociety/rightParty.png') no-repeat;
					background-size: cover;
					font-size: 30px;
					color: #2A9DFF;

					p:last-child {
						color: #fff;
						font-size: 16px;
						margin-top: 20px;
					}
				}
			}
		}

		.rightFoot {
			width: 100%;
			height: 280px;

			.data-list {
				margin-top: 10px;
			}
		}

	}
</style>
