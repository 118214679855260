<template>
  <div class="body-box">
    <div class="d-flex h-100 w-100">
      <!-- 左侧内容 -->
      <div class="left-wrapper d-flex flex-column">
        <div class="use-rank-wrapper d-flex flex-column">
          <div class="use-rank-content">
            <contentTop
              :type="2"
              title="各县区使用频率排行"
              :topOptionsProps="useRankSelectProps"
            />
          </div>
          <div class="use-rank-list-content">
            <rank-list
              viewType="opther"
              :listHeight="465"
              :tableTitle="userRankTableTitleList"
              :list="userRankList"
            />
          </div>
        </div>
        <div class="use-status-wrapper d-flex flex-column">
          <div class="use-status-top-con">
            <contentTop :type="2" title="性别与年龄分布" />
          </div>
          <div class="use-status-con d-flex flex-column">
            <div class="slider-process-wrapper">
              <i class="lade-icon"></i>
              <div class="slider-process-content">
                <div class="slider-process-left" :style="'width:'+ codeData.man+'%'">
                  <span class="num">{{codeData.man}}%</span>
                </div>
                <div class="slider-process-right" :style="'width:'+ codeData.woman+'%'">
                  <span class="num">{{ codeData.woman}}%</span>
                </div>
              </div>
              <i class="fale-icon"></i>
            </div>
            <p class="distribute-center-text">年龄分布</p>
            <div class="data-distribute d-flex h-100 w-100">
              <div class="distribute-left-content w-100">
                <div class="distribute-img"></div>
                <div v-for="(item,index) in codeData.data" :key="index"
                     :class="`distribute-process-item line-${index} distribute-center-text`">
                  {{item.float}}%
                </div>
              </div>
              <div class="distribute-right-content d-flex flex-column">
                <div v-for="(item,index) in codeData.data" :key="index" :class="`distribute-data-item line-${index}`">
                  {{item.title}}岁 {{item.num}}人
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间内容 -->
      <div class="middle h-100">
        <div class="middle-con d-flex flex-column h-100">
          <div class="sport-code-total-con">
            <p class="sport-title-con">
              <span class="sport-title">运动码总人次</span>
            </p>
            <light-plate :lightNum="totalNumber" />
          </div>
          <div class="sport-bg"></div>
          <div class="one-use-rank-wrapper">
            <div class="one-use-rank-top-con">
              <contentTop
                title="运动码个人使用情况排行"
                :topRadioProps="oneUseRadioProps"
                :topOptionsProps="oneUseRankSelectProps"
              />
            </div>
            <div class="one-use-rank-con">
              <rank-list
                :listHeight="340"
                :tableTitle="oneUseTableTitle"
                :list="oneUseList"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧内容 -->
      <div class="right-wrapper d-flex flex-column">
        <div class="city-rank-wrapper d-flex flex-column">
          <div class="city-rank-content">
            <contentTop
              :type="2"
              title="市级协会使用频率排行"
              :topOptionsProps="cityRankSelectProps"
            />
          </div>
          <div class="city-rank-list-content">
            <rank-list
                :listHeight="360"
                :tableTitle="areaTableTitleList"
                :list="cityRankOptions"
            />
          </div>
        </div>
        <div class="area-use-rank-wrapper d-flex flex-column">
          <div class="area-use-rank-top-con">
            <contentTop
              :type="2"
              title="各区县协会使用频率排行"
              :topOptionsProps="areaUseRankSelectProps"
            />
          </div>
          <div class="area-use-rank-con">
            <rank-list
              :listHeight="480"
              :tableTitle="areaTableTitleList"
              :list="areaList"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contentTop from '@/components/contentTop'
import rankList from '@/components/rankList'
import Sliders from '@/components/Sliders'
import lightPlate from '@/components/lightPlate'
import service from "@/utils/request";
import {numberToMoney} from "@/utils";

export default {
  name: 'sportCode',
  components: { contentTop, rankList, Sliders, lightPlate },
  data() {
    return {
      totalNumber: [0,0,0,0,0,0,0,0],
      codeData: {
        data: [],
        man: 0,
        woman: 0
      },
      // 各县区使用频率排行
      useRankSelectProps: {
        selects: [],
      },
      userRankTableTitleList: [
        { title: '排名', width: '80' },
        { title: '名称', val: 'first', width: '160' },
        { title: '使用人次', val: 'second', width: '160' },
      ],
      userRankList: [
        { first: '瓯海区', second: '90%' },
        { first: '龙湾区', second: '90%' },
        { first: '鹿城区', second: '90%' },
        { first: '永嘉县', second: '90%' },
        { first: '乐清市', second: '90%' },
        { first: '瑞安市', second: '90%' },
        { first: '文成县', second: '90%' },
        { first: '平阳县', second: '90%' },
        { first: '龙港市', second: '90%' },
        { first: '洞头区', second: '90%' },
      ],
      // 运动码个人使用情况排行
      oneUseRadioProps: {
        name: 'onuseradio',
        width: 100,
        options: [],
      },
      oneUseRankSelectProps: {
        selects: [],
      },
      oneUseTableTitle: [
        { title: '排名', width: '80' },
        { title: '用户姓名', val: 'first', width: '360' },
        { title: '使用次数', val: 'second', width: '180' },
      ],
      oneUseList: [
        { first: '袁鑫', second: '30', third: '92%' },
      ],
      // 市级协会使用频率排行
      cityRankSelectProps: {
        selects: [],
      },
      cityRankOptions: [],
      // 各区县协会使用频率排行
      areaUseRankSelectProps: {
        selects: [],
      },
      areaTableTitleList: [
        { title: '排名', width: '80' },
        { title: '名称', val: 'first', width: '180' },
        { title: '使用次数', val: 'second', width: '180' },
      ],
      areaList: [
        { first: '瓯海区', second: '90%' },
        { first: '龙湾区', second: '90%' },
        { first: '鹿城区', second: '90%' },
        { first: '永嘉县', second: '90%' },
        { first: '乐清市', second: '90%' },
        { first: '瑞安市', second: '90%' },
        { first: '文成县', second: '90%' },
        { first: '平阳县', second: '90%' },
        { first: '龙港市', second: '90%' },
        { first: '洞头区', second: '90%' },
      ],
      timing: null,
    }
  },
  mounted() {
    this.getbasicInfoData();
    this.getareaRankingData();
    this.getmemberRankingData();
    this.getuserEnrollData();
    this.gethotRankingData(4);
    this.gethotRankingData(5);
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getbasicInfoData();
        this.getareaRankingData();
        this.getmemberRankingData();
        this.getuserEnrollData();
        this.gethotRankingData(4);
        this.gethotRankingData(5);
      }, 5000);
    },
    getbasicInfoData() {
      // 基础数据（屏幕中间）
      const myThis = this;
      service.get('/ydm/basicInfo')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            let total = data.total + '';
            if(total.length < 8) {
              const length = 8 - total.length;
              for (let i = 0; i < length; i++) {
                total = '0'+total;
              }
            }
            myThis.totalNumber = total.split("");
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getmemberRankingData() {
      // 运动码个人使用情况排行
      const myThis = this;
      service.get('/ydm/memberRanking')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.oneUseList = data.map((item) => {
              return {
                first: item.name,
                second: item.num
              }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getareaRankingData() {
      // 各区县使用频率排行
      const myThis = this;
      service.get('/ydm/areaRanking')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.userRankList = data.map((item) => {
              return {
                first: item.name,
                second: item.num
              }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getuserEnrollData() {
      // 性别与年龄分布
      const myThis = this;
      service.get('/ydm/userEnroll')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            let allData = 0;
            if(data.woman != 0 && data.man != 0) {
              myThis.codeData.man = (data.man * 100 / (data.man + data.woman)).toFixed(1);
              myThis.codeData.woman = (data.woman * 100 / (data.man + data.woman)).toFixed(1);
            } else {
              myThis.codeData.man = 0;
              myThis.codeData.woman = 0;
            }
            data.list.map((item) => {
              allData = allData + item.num;
            });
            const list = data.list.sort((a,b) => b.num - a.num);
            myThis.codeData.data = list.map((item) => {
              let float = 0;
              if(allData != 0) {
                float = (item.num * 100 / allData).toFixed(1);
              }
              return { title:item.title, num: numberToMoney(item.num),float: float}
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    gethotRankingData(code) {
      // 使用频率右侧排行
      const myThis = this;
      service.get('/ydm/hotRanking', {params: {act_level: code}})
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            if(code == 4)
            {
              myThis.cityRankOptions = data.map((item) => (
                  { first: item.org_com, second: item.num }
              ));
            }
            if(code == 5){
              myThis.areaList = data.map((item) => (
                  { first: item.org_com, second: item.num }
              ));
            }
          })
          .catch((response) => {
            console.log(response)
          })
    },
    sliderChange(index, value) {
      console.log(index, value);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/sportCode.scss';
</style>
