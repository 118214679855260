<template>
  <div class="LeftInfoContainer">
    <div class="tborder">
      <div class="th">
        <div class="td">县市区</div>
        <div class="td">总数</div>
        <div class="td">城市健身房</div>
        <div class="td">农村健身房</div>
      </div>
      <div class="tbody">
        <div class="tr" v-for="(item, index) in tbodyData" :key="index">
          <div class="td">{{ item.id }}</div>
          <div class="td">{{ item.total }}</div>
          <div class="td">{{ item.value1 }}</div>
          <div class="td">{{ item.value2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tbodyData: [],
    };
  },
  mounted() {
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      // 健身房信息
      this.tbodyData = [
        {
          id: "鹿城区",
          total: 73,
          value1: 38,
          value2: 35,
        },
        {
          id: "龙湾区",
          total: 58,
          value1: 19,
          value2: 39,
        },
        {
          id: "瓯海区",
          total: 51,
          value1: 23,
          value2: 28,
        },
        {
          id: "洞头区",
          total: 27,
          value1: 7,
          value2: 20,
        },
        {
          id: "乐清市",
          total: 54,
          value1: 19,
          value2: 35,
        },
        {
          id: "瑞安市",
          total: 41,
          value1: 17,
          value2: 24,
        },
        {
          id: "永嘉县",
          total: 29,
          value1: 10,
          value2: 19,
        },
        {
          id: "文成县",
          total: 17,
          value1: 5,
          value2: 12,
        },
        {
          id: "平阳县",
          total: 41,
          value1: 14,
          value2: 27,
        },
        {
          id: "泰顺县",
          total: 18,
          value1: 7,
          value2: 11,
        },
        {
          id: "苍南县",
          total: 38,
          value1: 13,
          value2: 25,
        },
        {
          id: "龙港市",
          total: 21,
          value1: 13,
          value2: 8,
        },
        {
          id: "总计",
          total: 468,
          value1: 185,
          value2: 283,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.LeftInfoContainer {
  margin-top: 12px;
}

.tborder {
  border: 1px solid rgba(19, 41, 82, 0.2);
  border-radius: 8px;
  overflow: hidden;
  .th {
    // padding: 12px 24px;
    height: 36px;
    display: flex;
    align-items: center;
    background: rgba(19, 41, 82, 1);
    border: 1px solid rgba(91, 183, 197, 0.2);
    .td {
    height: 36px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(91, 183, 197, 0.2);
      &:nth-last-of-type(1) {
        border: none;
      }
    }
  }
  .tbody {
    border: 1px solid rgba(91, 183, 197, 0.2);
    border-radius: 0 0 8px 8px;
    .tr {
      display: flex;
      align-items: center;
      justify-content: center;

      .td {
        padding: 4px 6px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(91, 183, 197, 0.2);
      }
    }
  }
}
</style>
