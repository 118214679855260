<template>
  <div id="centerMap">
    <div class="centerMapContainer">
      <div id="echartMap" ref="echartMap" class="echartMap"></div>
      <div class="mapTip">地区热力指数</div>
      <div class="areaBox">
        <CenterArea :rateList="billboardList"/>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import 'echarts-gl'

import wz from '../../../assets/source/wz.json'
// import jn from "../../../assets/source/jn.json";
import CenterArea from './CenterArea'
import service from "@/utils/request";
import {formatTime} from "@/utils";

export default {
  components: {
    CenterArea,
  },
  data() {
    return {
      timing: null,
      mapdata: [],
      billboardList: [],
      pointsData: [
        [120.637145, 28.006444],
        [120.690968, 28.153886],
        [120.406256, 27.507743],
        [120.967147, 28.116083],
        [120.09245, 27.789133],
        [119.71624, 27.557309],
      ],
      mapChart: null,
      options: {
        backgroundColor: 'transparent',
        visualMap: {
          type: 'piecewise',
          min: 0,
          max: 100,

          textStyle: {
            color: '#fff',
            fontSize: 14,
          },
          pieces: [
            { gt: 0, lte: 3, color: '#DA7746', label: '热度lv4 -' },
            { gt: 3, lte: 6, color: '#ffd942', label: '热度lv3 -' },
            { gt: 6, lte: 8, color: '#D39C2D', label: '热度lv2 -' },
            { gt: 8, lte: 100, color: '#0A3888', label: '热度lv1 -' },
          ],
          // itemWidth: 10, //图形的宽度，即长条的宽度。
          // itemHeight: 90, //图形的高度，即长条的高度。
          align: 'auto', //指定组件中手柄和文字的摆放位置.可选值为：‘auto’ 自动决定。‘left’ 手柄和label在右。‘right’ 手柄和label在左。‘top’ 手柄和label在下。‘bottom’ 手柄和label在上。
          left: '85%', //组件离容器左侧的距离,‘left’, ‘center’, ‘right’,‘20%’
          top: '21% ', //组件离容器上侧的距离,‘top’, ‘middle’, ‘bottom’,‘20%’
          right: 'auto', //组件离容器右侧的距离,‘20%’
          bottom: 'auto', //组件离容器下侧的距离,‘20%’
          orient: 'vertical', //图例排列方向
          show: true,
        },
        geo: [
          {
            map: 'organ_diagram',
            aspectScale: 1,
            zoom: 0.8,
            layoutCenter: ['50%', '50%'],
            layoutSize: '108%',
            roam: false,
            show: true,
            itemStyle: {
              normal: {
                areaColor: '#f5f5f5',
                shadowColor: 'rgba(56, 171, 241, 0.2)',
                shadowOffsetX: 0,
                shadowOffsetY: 15,
              },
              emphasis: {
                areaColor: '#2AB8FF',
                borderWidth: 0,
                color: 'green',
                label: {
                  show: false,
                },
              },
            },
          },
          // 重影
          {
            type: 'map',
            map: 'organ_diagram',
            zlevel: -1,
            aspectScale: 1,
            zoom: 0.8,
            layoutCenter: ['50%', '51%'],
            layoutSize: '108%',
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                borderWidth: 1,
                // borderColor:"rgba(17, 149, 216,0.6)",
                borderColor: 'rgba(58,149,253,0.8)',
                shadowColor: 'rgba(172, 122, 255,0.5)',
                shadowOffsetY: 5,
                shadowBlur: 15,
                areaColor: 'rgba(5,21,35,0.1)',
              },
            },
          },
          {
            type: 'map',
            map: 'organ_diagram',
            zlevel: -2,
            aspectScale: 1,
            zoom: 0.8,
            layoutCenter: ['50%', '52%'],
            layoutSize: '108%',
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                borderWidth: 1,
                borderColor: 'rgba(58,149,253,0.6)',
                shadowColor: 'rgba(65, 214, 255,1)',
                shadowOffsetY: 5,
                shadowBlur: 10,
                areaColor: 'transpercent',
              },
            },
          },
        ],
        // grid: {
        //   left: '50%',
        //   top: '20%',
        //   bottom: '20%'
        // },
        series: [
          {
            name: 'French Beef Cuts',
            type: 'map',
            map: 'organ_diagram',
            roam: false,
            zoom: 0.8,
            layoutCenter: ['50%', '50%'], //地图位置
            layoutSize: '108%',
            aspectScale: 1,
            // right: "800",
            label: {
              normal: {
                show: true,
                color: '#fff',
                fontSize: 16,
                fontWeight: 600,
              },
              emphasis: {
                show: true,
                color: '#fff',
              },
            },
            itemStyle: {
              normal: {
                borderWidth: 1,
                borderColor: 'rgba(255,255,255,0.4)',
              },
            },
            selectedMode: true,
            data: [],
          },
        ],
      }
    }
  },
  mounted() {
    this.getEchartMap();
    this.getData();
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      const myThis = this;
      service.get('/synthesis/sportData')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            const options = myThis.options;
            options.series[0].data = data.list.map((item,index) => {
              const tmp = wz.features.filter((child) => child.properties.adcode == parseInt(item.code.substr(0,6)));
              const cityName = tmp[0].properties.name;
              return { value: index+1, name: cityName}
            });
            myThis.mapChart.setOption(options, true);
            myThis.billboardList = data.list.map((item,index) => {
              const tmp = wz.features.filter((child) => child.properties.adcode == parseInt(item.code.substr(0,6)));
              const cityName = tmp[0].properties.name;
              return { value: item.num, name: cityName}
            });
          })
          .catch((response) => {
            console.log(response);
          })
    },
    getEchartMap() {
      echarts.registerMap('organ_diagram', wz)
      var chart = echarts.init(document.getElementById('echartMap'))
      this.mapChart = chart;
      this.mapChart.setOption(this.options,true);
      var flag = true // 为了做判断：当鼠标移动上去的时候，自动高亮就被取消
      var _this = this
      var index = 0
      // 鼠标移动上去的时候的高亮动画
      chart.on('mouseover', function (param) {
        flag = false
        clearInterval(_this.startCharts)
        //取消之前高亮图形
        chart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: index,
        })

        index = param.dataIndex
        //**解决文章所述问题**// //记录上一次选中值重新进入时取消高亮

        //高亮当前图形
        chart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        })
        //显示tooltip
        chart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        })
      })

      //图标随窗口大小缩放
      window.addEventListener('resize', function () {
        chart.resize()
      })
      chart.on('click', function (event) {
        console.log(event)
        chart.dispatchAction({
          type: 'highlight',
          geoIndex: 100,
          name: event.name,
        })
      })

      //自动高亮显示
      var chartHover = function () {
        var dataLen = 2

        // 取消之前高亮的图形
        chart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: index,
        })
        index = (index + 1) % dataLen

        // 高亮当前图形
        chart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: index,
        })
        // 显示 tooltip
        chart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index,
        })
      }

      //_this.startCharts = setInterval(chartHover, 5000)
      // 4、鼠标移出之后，恢复自动高亮
      chart.on('mouseout', function (param) {
        chart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: index,
        })
        if (!flag) {
          //_this.startCharts = setInterval(chartHover, 5000)
          flag = true
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
#centerMap {
  .centerMapContainer {
    // height: 262px;
    width: 100%;
    height: 600px;
    position: relative;
    .flyBox {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1000000;
      //   background: #f5f5f5;
    }
    #echartMap {
      width: 100%;
      height: 600px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-60%, -50%);
      //   margin: 0 auto;
    }

    .mapTip {
      position: absolute;
      top: 20.5%;
      right: 8%;
      width: 20px;
      color: #fff;
      font-size: 16px;
    }

    .areaBox {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    // padding: 20px 30px;
  }
}
</style>
