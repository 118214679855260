<template>
  <div class="d-flex CenterTopContainer">
    <AccumulateTip
      :width="66"
      label="体育场地设施数量"
      :count="7366789"
      :labelWidth="100"
      :countMargin="14"
      unit="个"
    />
    <AccumulateTip
      :width="66"
      label="体育场地总面积"
      :count="7366789"
      :labelWidth="100"
      :countMargin="14"
      unit="㎡"
    />
  </div>
</template>
              
              <script>
import AccumulateTip from "@/components/accumulateTip";
export default {
  components: {
    AccumulateTip,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
              
              <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.CenterTopContainer {
    justify-content: space-around;
}
</style>
              