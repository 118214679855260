<template>
  <div class="RightBottomContainer d-flex">
    <div class="imgBox">
      <img src="~@/assets/sportFacilities/alarm.png" alt="" />
    </div>
    <div class="alarmTitle">黑榜预警系统</div>
  </div>
</template>
              
              <script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
              
              <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.RightBottomContainer {
  justify-content: space-between;
  align-items: center;
  .imgBox {
    width: 238px;
    height: 131px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .alarmTitle {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
  }
}
</style>
              