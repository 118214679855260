<template>
  <div class="container">
    <div class="sportWrapper">
      <div class="sportLeft d-flex flex-column">
        <LeftArea />
        <LeftBuild />
      </div>
      <div class="sportCenter">
        <CenterTop />
        <CenterScore />
        <CenterServer />
      </div>
      <div class="sportRight">
        <RightTop />
        <RightCenter />
        <RightBottom />
      </div>
    </div>
  </div>
</template>
    
    <script>
import echarts from "echarts";
import drawMixin from "@/utils/drawMixin";
import { formatTime } from "@/utils/index.js";
import LeftArea from "./components/LeftArea";
import LeftBuild from "./components/LeftBuild";
import RightTop from "./components/RightTop";
import RightCenter from "./components/RightCenter";
import RightBottom from "./components/RightBottom";
import CenterTop from "./components/centerTop";
import CenterServer from "./components/CenterServer";
import CenterScore from "./components/CenterScore";

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
    };
  },
  components: {
    LeftArea,
    LeftBuild,
    RightTop,
    RightCenter,
    RightBottom,
    CenterTop,
    CenterServer,
    CenterScore,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../../assets/scss/index.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 16px;
  & > div {
    height: 950px;
  }
  .sportWrapper {
    width: 100%;
    display: flex;
    // padding: 0 49px;
    .sportLeft {
      width: 427px;
      justify-content: space-between;
      // border: 1px solid #fafafa;
    }
    .sportCenter {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 51px;
      // background: gray;
    }
    .sportRight {
      width: 434px;
      // background: red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
    