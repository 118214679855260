<template>
  <div class="body-box">
    <div class="d-flex h-100 w-100">
      <!-- 左侧内容 -->
      <div class="left-wrapper d-flex flex-column">
        <div class="instructor-type-wrapper d-flex flex-column">
          <div class="instructor-type-top-wrapper">
            <contentTop
              :type="2"
              title="指导员类型"
              :topOptionsProps="instructorTypeSelectProps"
            />
          </div>
          <div class="instructor-type-content">
            <air-bubble />
          </div>
        </div>
        <div class="instructor-rank-wrapper">
          <div class="instructor-rank-top-wrapper">
            <contentTop
              :type="2"
              title="指导员排名"
              :topOptionsProps="instructorRankSelectProps"
            />
          </div>
          <div class="instructor-rank-radio-wrapper d-flex jc-end">
            <Radio :radioProps="instructorRankRadioProps" />
          </div>
          <div class="instructor-rank-slider-wrapper">
            <Sliders
              :options="instructorRankSliderProps"
              :isHasRight="true"
              :rightTextWidth="65"
            />
          </div>
        </div>
        <div class="lesson-time-wrapper">
          <div class="lesson-time-top-wrapper">
            <contentTop
              :type="2"
              title="课程时间表"
              :topOptionsProps="lessonTimeSelectProps"
            />
          </div>
          <div class="lesson-time-radio-wrapper d-flex jc-end">
            <Radio :radioProps="lessonTimeRadioProps" />
          </div>
          <div class="lesson-time-echart">
            <line-chart
              :cdata="lessonCategoryOptions"
              contWidth="100%"
              contHeight="250px"
            />
          </div>
        </div>
      </div>
      <!-- 中间内容 -->
      <div class="middle d-flex flex-column">
        <div class="structor-date-wrapper d-flex w-100">
          <div class="date-item-content h-100 d-flex flex-column jc-between">
            <div class="date-top-con d-flex">
              <div class="icon-content">
                <i class="document"></i>
              </div>
              <div class="date--top-text d-flex-flex-column">
                <p class="num">15390<span class="unit">人</span></p>
                <p class="tip">指导员总人数</p>
              </div>
            </div>
            <div class="date-bottom-con d-flex">
              <div class="bottom-item d-flex flex-column">
                <p>10%</p>
                <p class="tip">本月新增</p>
              </div>
              <div class="bottom-item">
                <p>10%</p>
                <p class="tip">上月新增</p>
              </div>
            </div>
          </div>
          <div class="date-item-content h-100 d-flex flex-column jc-between">
            <div class="date-top-con d-flex">
              <div class="icon-content">
                <i class="lesson"></i>
              </div>
              <div class="date--top-text d-flex-flex-column">
                <p class="num">15390<span class="unit">场</span></p>
                <p class="tip">指导员课程数</p>
              </div>
            </div>
            <div class="date-bottom-con d-flex">
              <div class="bottom-item d-flex flex-column">
                <p>10%</p>
                <p class="tip">本月新增</p>
              </div>
              <div class="bottom-item">
                <p>10%</p>
                <p class="tip">上月新增</p>
              </div>
            </div>
          </div>
          <div class="date-item-content h-100 d-flex flex-column jc-between">
            <div class="date-top-con d-flex">
              <div class="icon-content">
                <i class="guide"></i>
              </div>
              <div class="date--top-text d-flex-flex-column">
                <p class="num">15390<span class="unit">堂</span></p>
                <p class="tip">指导员服务人次</p>
              </div>
            </div>
            <div class="date-bottom-con d-flex">
              <div class="bottom-item d-flex flex-column">
                <p>10%</p>
                <p class="tip">本月新增</p>
              </div>
              <div class="bottom-item">
                <p>10%</p>
                <p class="tip">上月新增</p>
              </div>
            </div>
          </div>
        </div>
        <div class="structor-process-wrapper d-flex w-100">
          <div class="user-process-content d-flex">
            <div class="user-process-left d-flex flex-column">
              <div class="wrapper-desc">
                <p class="title">指导员年龄占比及性别</p>
              </div>
              <div class="age-process-cont">
                <Echart :options="options" height="100%" width="100%"></Echart>
              </div>
              <div class="sex-process-cont d-flex flex-column w-100">
                <div class="fale w-100">
                  <div class="sex-box fale-box d-flex">
                    <span class="icon"></span>
                    <div class="process-date">
                      <span class="sex-text">男性</span>
                      <span class="num">67.8%</span>
                    </div>
                  </div>
                </div>
                <div class="woman w-100">
                  <div class="sex-box woman-box d-flex">
                    <span class="icon"></span>
                    <div class="process-date">
                      <span class="sex-text">女性</span>
                      <span class="num">67.8%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="user-process-center">
              <Echart
                style="transform: rotateX(50deg)"
                :options="rightOptions"
                height="350px"
                width="300px"
              ></Echart>
              <div class="siteNum">
                <p class="num">
                  {{ siteCoverProbability }} <span class="unit">%</span>
                </p>
                <p class="desc">运动码使用比例</p>
              </div>
            </div>
            <div class="user-process-left d-flex flex-column">
              <div class="wrapper-desc">
                <p class="title">指导员年龄占比及性别</p>
              </div>
              <div class="age-process-cont">
                <Echart :options="options" height="100%" width="100%"></Echart>
              </div>
              <div class="sex-process-cont d-flex flex-column w-100">
                <div class="fale w-100">
                  <div class="sex-box fale-box d-flex">
                    <span class="icon"></span>
                    <div class="process-date">
                      <span class="sex-text">男性</span>
                      <span class="num">67.8%</span>
                    </div>
                  </div>
                </div>
                <div class="woman w-100">
                  <div class="sex-box woman-box d-flex">
                    <span class="icon"></span>
                    <div class="process-date">
                      <span class="sex-text">女性</span>
                      <span class="num">67.8%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="train-wrapper d-flex flex-column w-100">
          <div class="train-top-wrapper w-100">
            <contentTop title="培训情况" />
          </div>
          <div class="train-content">
            <div class="train-box d-flex">
              <div class="train-left h-100 d-flex flex-column">
                <div class="train-item d-flex">
                  <span class="icon-box icon-online"></span>
                  <div class="train-text">
                    <p class="text">线上培训</p>
                    <p class="num">23548</p>
                    <p class="status">
                      较去年: 4% <i class="iconfont icon-shang"></i>
                    </p>
                  </div>
                </div>
                <div class="train-item d-flex">
                  <span class="icon-box icon-offline"></span>
                  <div class="train-text">
                    <p class="text">线下培训</p>
                    <p class="num">23548</p>
                    <p class="status">
                      较去年: 4% <i class="iconfont icon-xiangxia3"></i>
                    </p>
                  </div>
                </div>
              </div>
              <div class="train-right">
                <div class="train-echart h-100">
                  <line-chart
                    class="h-100"
                    :cdata="trainCategoryOptions"
                    contWidth="100%"
                    contHeight="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧内容 -->
      <div class="right-wrapper">
        <div class="rank-wrapper">
          <div class="rank-top-wrapper">
            <contentTop
              :type="2"
              title="赛事排行榜"
              :topOptionsProps="rankSelectProps"
            />
          </div>
          <div class="rank-content">
            <rank-list
              :listHeight="550"
              :tableTitle="matchTableTitleList"
            />
          </div>
        </div>
        <div class="perform-wrapper">
          <div class="perform-top-wrapper">
            <img src="@/assets/matchActivity/matchServer.png" />
            <div class="perform-text-content">
              <p class="perform-title">低履职人数:</p>
              <p class="perform-num">
                944,789,00<span class="perform-unit">人</span>
              </p>
            </div>
          </div>
          <div class="perform-content">
            <div class="list">
              <div class="listTitle">
                <div class="titleItem">姓名</div>
                <div class="titleItem">低履职率</div>
              </div>
              <div class="listTable">
                <div v-for="item in tempList" :key="item">
                  <div class="titleItem">{{ item.name }}</div>
                  <div class="titleItem">
                    <progress-bar :val="item.value" style="flex: 1" />
                    <span>{{ item.value }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contentTop from '@/components/contentTop'
import Radio from '@/components/Radio'
import Sliders from '@/components/Sliders'
import lineChart from '@/components/lineChart'
import echarts from 'echarts'
import rankList from '@/components/rankList'
import progressBar from '@/components/progressBar'
import airBubble from '@/components/airBubble'
import Echart from '@/common/echart'

export default {
  name: 'socialInstructor',
  components: {
    contentTop,
    Radio,
    Sliders,
    lineChart,
    rankList,
    progressBar,
    airBubble,
    Echart,
  },
  data() {
    return {
      // 指导员类型
      instructorTypeSelectProps: {
        selects: [
          {
            name: 'instructor-type',
            options: [{ value: 1, label: '鹿城区' }],
          },
        ],
      },
      // 指导员排名
      instructorRankSelectProps: {
        selects: [
          {
            name: 'instructor-type',
            options: [{ value: 1, label: '鹿城区' }],
          },
        ],
      },
      instructorRankRadioProps: {
        name: 'instructorRank',
        width: 262,
        options: [
          { value: 1, label: '总人数' },
          { value: 2, label: '课程数' },
          { value: 3, label: '服务人次' },
          { value: 4, label: '上岗履职率' },
        ],
      },
      instructorRankSliderProps: [
        { value: 100, label: '鹿城区', rightText: '12000人' },
        { value: 90, label: '瓯海区', rightText: '9876人' },
        { value: 80, label: '龙湾区', rightText: '9876人' },
        { value: 70, label: '洞头区', rightText: '9876人' },
        { value: 60, label: '永嘉县', rightText: '9876人' },
      ],
      // 课程时间表
      lessonTimeSelectProps: {
        selects: [
          {
            name: 'lessonTime',
            options: [{ value: 1, label: '鹿城区' }],
          },
        ],
      },
      lessonTimeRadioProps: {
        name: 'lessonTimeRadio',
        width: 130,
        options: [
          { value: 1, label: '日' },
          { value: 2, label: '月' },
          { value: 3, label: '周' },
          { value: 4, label: '年' },
        ],
      },
      lessonCategoryOptions: {},
      // 运动使用比例
      siteCoverProbability: 85,
      rightOptions: {},
      // 年龄占比
      options: {},
      // 培训情况饼图
      trainCategoryOptions: {},
      // 排行榜
      rankSelectProps: {
        selects: [
          {
            name: 'rank1',
            options: [{ value: 1, label: '红榜' }],
          },
          {
            name: 'rank2',
            options: [{ value: 2, label: '鹿城区' }],
          },
        ],
      },
      matchTableTitleList: [
        { title: '排名', width: '80' },
        { title: '姓名', val: 'first', width: '120' },
        { title: '课程几次', val: 'second', width: '120' },
        { title: '服务人次', val: 'three', width: '140' },
      ],
      matchList: [
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
        { first: '顾晚', second: '1000', thrid: '2000' },
      ],
      tempList: [
        { name: '王晓婷', value: 58 },
        { name: '胡丹', value: 56 },
        { name: '张伟剑', value: 52 },
        { name: '袁涛', value: 49 },
        { name: '袁星', value: 44 },
        { name: '李航', value: 40 },
        { name: '张伟', value: 38 },
      ]
    }
  },
  mounted() {
    this.setCenterPie()
    this.setLessonCategory()
    this.setTrainCategory()
    this.setRightPie(this.siteCoverProbability)
  },
  methods: {
    // 课程时间表柱图
    setLessonCategory() {
      this.lessonCategoryOptions = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '18%',
          left: '8%',
          right: '0%',
          bottom: '8%',
        },
        categoryAxis: {
          position: 'bottom',
        },
        xAxis: [
          {
            type: 'category',
            data: ['1-6h', '6-12h', '12-18h', '18-24h'],
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            // 网格线
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255,255,255,0.20)'],
                width: 1,
                type: 'dashed',
              },
            },
            type: 'value',
            name: 'h',
            min: 0,
            max: 800,
            interval: 200,
          },
        ],
        series: [
          {
            name: '站点数量',
            type: 'bar',
            legendHoverLink: true,
            barWidth: '20px',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' h'
              },
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: 'rgba(8,253,254,0)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(8,253,254,1)',
                    },
                  ],
                  false
                ),
              },
            },
            data: [700, 390, 300, 400],
          },
        ],
      }
    },
    // 年龄占比饼图
    setCenterPie() {
      this.options = {
        series: [
          // 显示外侧标签
          {
            name: 'chart',
            type: 'pie',
            roseType: false,
            radius: [62, 90],
            hoverAnimation: false,
            center: ['49%', '49%'],
            label: {
              normal: {
                show: true,
                // position: 'center',
                color: '#fff',
                formatter: ['{name|{b}}', '{b|{c}%}'].join('\n'),
                rich: {
                  a: {
                    color: '#fff',
                    fontSize: 14,
                    textAlign: 'left',
                  },
                },
              },
              emphasis: {
                //中间文字显示
                show: true,
                formatter: ['{name|{b}}', '{b|{c}%}'].join('\n'),
              },
            },
            itemStyle: {
              normal: {
                label: {
                  show: true, //隐藏文字
                },
                labelLine: {
                  show: true, //隐藏指示线
                },
              },
            },
            data: [
              {
                value: 21,
                name: '50岁以上',
                itemStyle: {
                  color: '#876FF7',
                },
              },
              {
                value: 40,
                name: '16-30岁',
                itemStyle: {
                  color: '#10BEFF',
                },
              },
              {
                value: 21,
                name: '5-15岁',
                itemStyle: {
                  color: '#17DD7B',
                },
              },
              {
                value: 21,
                name: '31-55岁',
                itemStyle: {
                  color: '#FF8C39',
                },
              },
            ],
            animationEasing: 'cubicInOut',
            animationDuration: 2600,
          },
          // 显示中间文字
          {
            name: 'chart',
            type: 'pie',
            roseType: false,
            radius: [62, 90],
            hoverAnimation: false,
            center: ['49%', '49%'],
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#fff',
                formatter: ['{a|各年龄}', '{a|占比}'].join('\n'),
                rich: {
                  a: {
                    color: '#fff',
                    fontSize: 22,
                    textAlign: 'center',
                  },
                },
              },
              emphasis: {
                //中间文字显示
                show: true,
              },
            },
            itemStyle: {
              normal: {
                label: {
                  show: false, //隐藏文字
                },
                labelLine: {
                  show: false, //隐藏指示线
                },
              },
            },
            data: [
              {
                value: 21,
                name: '50岁以上',
                itemStyle: {
                  color: '#876FF7',
                },
              },
              {
                value: 40,
                name: '16-30岁',
                itemStyle: {
                  color: '#10BEFF',
                },
              },
              {
                value: 21,
                name: '5-15岁',
                itemStyle: {
                  color: '#17DD7B',
                },
              },
              {
                value: 21,
                name: '31-55岁',
                itemStyle: {
                  color: '#FF8C39',
                },
              },
            ],
            animationEasing: 'cubicInOut',
            animationDuration: 2600,
          },
        ],
      }
    },
    // 培训情况柱图
    setTrainCategory() {
      this.trainCategoryOptions = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '18%',
          left: '8%',
          right: '0%',
          bottom: '9%',
        },
        legend: {
          top: '2%',
          right: '0',
          data: [
            {
              name: '线上培训',
              icon: 'rect',
            },
            {
              name: '线下培训',
              icon: 'rect',
            },
            {
              name: '线上培训(较去年)',
              icon: 'line',
            },
            {
              name: '线下培训(较去年)',
              icon: 'line',
            },
          ],
        },
        categoryAxis: {
          position: 'bottom',
        },
        xAxis: [
          {
            type: 'category',
            data: [
              '瓯海区',
              '龙湾区',
              '鹿城区',
              '永嘉县',
              '乐清市',
              '瑞安市',
              '文成县',
              '平阳县',
              '龙港市',
              '洞头区',
              '泰顺县',
              '苍南县',
            ],
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            // 网格线
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255,255,255,0.20)'],
                width: 1,
                type: 'dashed',
              },
            },
            type: 'value',
            name: '次',
            min: 0,
            max: 800,
            interval: 200,
          },
        ],
        series: [
          {
            name: '线上培训',
            type: 'bar',
            legendHoverLink: false,
            barWidth: '15px',
            tooltip: {
              trigger: 'item',
              formatter: function (data) {
                return `
                      <div style="background: rgba(48,48,48,0.70);padding: 10px;">
                        <p style="font-size: 14px; line-height: 16px;">培训情况：</p>
                        <p style="font-size: 12px; line-height: 16px;">总数:${data.value}</p>
                        <div style="font-size: 12px; line-height: 16px;">较去年: 12% <i class="iconfont icon-shang"></i></div>
                      </div>
                `
              },
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: 'rgba(8,253,254,0.00)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(8,253,254,1.00)',
                    },
                  ],
                  false
                ),
              },
            },
            data: [700, 490, 300, 420, 700, 390, 300, 400, 700, 390, 300, 400],
          },
          {
            name: '线下培训',
            type: 'bar',
            legendHoverLink: false,
            barWidth: '15px',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255,161,160,0.00)',
                    },
                    {
                      offset: 1,
                      color: '#ff6766',
                    },
                  ],
                  false
                ),
              },
            },
            data: [700, 490, 300, 420, 700, 390, 300, 400, 700, 390, 300, 400],
          },
          {
            name: '线上培训(较去年)',
            type: 'line',
            smooth: false,
            legendHoverLink: false,
            itemStyle: {
              normal: {
                color: '#08FDFE',
              },
            },
            data: [100, 290, 300, 420, 400, 590, 300, 600, 700, 490, 200, 400],
          },
          {
            name: '线下培训(较去年)',
            type: 'line',
            legendHoverLink: false,
            smooth: false,
            itemStyle: {
              normal: {
                color: '#ff6766',
              },
            },
            data: [700, 490, 300, 420, 700, 390, 300, 400, 700, 390, 300, 400],
          },
        ],
      }
    },
    //站点覆盖率饼图
    setRightPie(num) {
      num = Math.floor(num / 5)
      let opData = []
      for (let i = 1; i < 20; i++) {
        let item = {
          value: 1,
          name: i <= num ? '覆盖率' : '未覆盖',
          itemStyle: {
            color: i <= num ? '#2A9DFF' : '#fff',
          },
        }
        opData.push(item)
      }
      this.rightOptions = {
        series: [
          {
            name: 'chart',
            type: 'pie',
            roseType: false,
            radius: [75, 100],
            hoverAnimation: false,
            center: ['50%', '50%'],
            label: {
              emphasis: {
                //中间文字显示
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderColor: 'rgba(35,76,133, 0.3)',
                borderWidth: 10,
                label: {
                  show: false, //隐藏文字
                },
                labelLine: {
                  show: false, //隐藏指示线
                },
              },
            },
            data: opData,
            animationEasing: 'cubicInOut',
            animationDuration: 2600,
          },
          {
            name: '外边框1',
            type: 'pie',
            tooltip: {
              show: false,
            },
            clockWise: false,
            hoverAnimation: false,
            center: ['50%', '50%'],
            radius: ['80%', '80%'],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: 'rgba(13,99,140)', //边框颜色
                  },
                },
              },
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 2,
                    borderColor: 'rgba(18,139,183)', //边框颜色
                  },
                },
              },
            ],
          },
          {
            name: '外边框2',
            type: 'pie',
            tooltip: {
              show: false,
            },
            clockWise: false,
            hoverAnimation: false,
            center: ['50%', '50%'],
            radius: ['90%', '90%'],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 3,
                    borderColor: '#0b6083', //边框颜色
                    shadowBlur: 20, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                    shadowOffsetX: 0, // 阴影水平方向上的偏移距离
                    shadowOffsetY: 0,
                    shadowColor: '#0b6083',
                  },
                },
              },
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    borderColor: '#18D2FE', //边框颜色
                    shadowBlur: 20, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                    shadowOffsetX: 0, // 阴影水平方向上的偏移距离
                    shadowOffsetY: 0,
                    shadowColor: '#18D2FE',
                  },
                },
              },
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 3,
                    borderColor: '#0b6083', //边框颜色
                    shadowBlur: 20, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                    shadowOffsetX: 0, // 阴影水平方向上的偏移距离
                    shadowOffsetY: 0,
                    shadowColor: '#0b6083',
                  },
                },
              },
            ],
          },
          {
            name: '内边框',
            type: 'pie',
            tooltip: {
              show: false,
            },
            clockWise: false,
            hoverAnimation: false,
            center: ['50%', '50%'],
            radius: ['35%', '35%'],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: 'rgba(13,99,140)', //边框颜色
                  },
                },
              },
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 2,
                    borderColor: 'rgba(24,114,250)', //边框颜色
                  },
                },
              },
              {
                value: 1,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: 'rgba(13,99,140)', //边框颜色
                  },
                },
              },
            ],
          },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/socialInstructor.scss';
</style>
