<template>
  <div class="RightTopContainer">
    <ContentTop title="十三个公共体育场馆的数字化" :type="2" />
    <div class="rank-radio d-flex jc-end w-100" style="margin-top: 20px">
      <Radio :radioProps="levelRankRadioProps" />
    </div>
    <div style="margin-top: 21px">
      <Sliders
        @sliderChange="sliderChange"
        :options="optData"
        :textWidth="120"
      />
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop'
import Sliders from '@/components/Sliders'
import Radio from '@/components/Radio'
export default {
  components: {
    ContentTop,
    Sliders,
    Radio,
  },
  data() {
    return {
      levelRankRadioProps: {
        name: 'levelRankRadio',
        width: 'auto',
        options: [
          { value: 'qu', label: '订单数' },
          { value: 'xian', label: '流量' },
        ],
      },
      optData: [
        { value: 100, label: '足球场馆', rightText: '40%' },
        { value: 90, label: '篮球场馆', rightText: '40%' },
        { value: 80, label: '网球场馆', rightText: '40%' },
        { value: 70, label: '田径场馆', rightText: '40%' },
        { value: 60, label: '乒乓球场馆', rightText: '40%' },
      ],
    }
  },
  methods: {
    sliderChange(index, value) {
      console.log(index, value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.RightTopContainer {
}
</style>
